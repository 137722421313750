import * as THREE from 'three';
import { Scene, MathUtils, PerspectiveCamera, Object3D, Vector3, ShaderMaterial, Vector, Color, DoubleSide, BufferGeometry } from 'three';
    
import { GameAsset } from './game-asset';
import { ScenePiece } from './scene-piece';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { ThrowStmt } from '@angular/compiler';
import { SceneBasic } from './scene-basic';

export class SceneAllPieces extends SceneBasic {
    public clues : Array<GameAsset>;
    // public pieces : Array<GameAsset>;

    public filepath : string;
    public randomColors : number[];
    
    constructor(width : number, height : number){
        super(width,height);
        this.clues= new Array<GameAsset>(0);
        // this.pieces= new Array<GameAsset>(0);

        this.controls.enabled = true;
        this.controls.enablePan = false;
        this.controls.enableZoom = false;
        this.randomColors = this.randomArray(16,1);
        // console.log( this.randomColors);
    }
    getRandomColor(min: number, max: number){
        // console.log(Math.random()* 0xffffff);

        let number =  Math.random() * (max - min) + min;
        const color = new THREE.Color();
        color.setHSL(number,0.2,0.5);
        // let number1 = Math.random() * (max - min) + min;
        // let number2 = Math.random() * (max - min) + min;
        // let number3 = Math.random() * (max - min) + min;

        // console.log("random number:",number);
        // return new THREE.Color(number,number*.8,number*.4)
        // return new THREE.Color(number1,number2,number3)
        return color;


    }
    randomArray(length  : number, max: number) {
        return Array.apply(null, Array(length)).map(function() {
            return Math.random() * max;
        });
    }

    setupModelCores(numPieces : number, clues: number[]) : Promise<Boolean>{
        return new Promise(resolve => {
            //we use an additional counter, which only gets increased when  the promise is delivered
             //now add the clues

            // var filename = "assets/puzzle4/puzzle13.glb";
            // console.log(filename);

            // var filenametexture = "assets/puzzle4/puzzle13_diffuse.png";
            // var filenametexturenormal = "assets/puzzle4/bake_0of11_lowres_n.png";

            //  new GameAsset().loadFile(filename)
            //  .then((result) =>{
            //     var i=0;
            //     console.log("Object",result);
            //     var puzzlePiece = new GameAsset();
            //     const textureDiff = new THREE.TextureLoader().load( 
            //     // resource URL
            //     filenametexture,

            //     // onLoad callback
            //      ( texture ) => {
            //         const textureNor = new THREE.TextureLoader().load( 
            //             // resource URL
            //             filenametexturenormal,
            //         ( texturenor ) => {
            //             texturenor.flipY = false;
            //             texture.flipY = false;

            //             // in this example we create the material when the texture is loaded
            //             const material = new THREE.MeshStandardMaterial( {
            //                 // color : new THREE.Color(0XFF0000),
            //             map: texture,
            //             // displacementMap: texturenor,
            //                 // opacity: 0.5,
            //             transparent: false,
            //             } );
                    

            //         var meshPiece1 = new THREE.Mesh(result[0].geometry, material);
            //         // meshPiece.visible = true;
            //         //set the name
            //        //  puzzlePiece.name = "clue_"+counter.toString();
            //        //  meshPiece.name = "clue_"+counter.toString();
            //         meshPiece1.position.set(result[i].position.x, result[i].position.y, result[i].position.z);
            //         //compute its bounding box
            //         puzzlePiece.bbox = new THREE.Box3().setFromObject(meshPiece1);
            //         puzzlePiece.bbox.getCenter(puzzlePiece.centerModel);
                    

            //         // this.clues.push(puzzlePiece);
            //         this.scene.add(meshPiece1);

            //     },

            //         // onProgress callback currently not supported
            //         undefined,

            //         // onError callback
            //         function ( err ) {
            //         console.error( 'An error happened.' );
            //         }
            //         );
            //         },

            //         // onProgress callback currently not supported
            //         undefined,

            //         // onError callback
            //         function ( err ) {
            //         console.error( 'An error happened.' );
            //         }
            //         );


            //     //  puzzlePiece.uniforms.diffuseColor.value = new Color(0XFFFF00);
            //     //  puzzlePiece.material.depthTest = true;
            //     //  this.scene.add(result[i]);

                
            //     //  this.scene.add(result[0]);
            //     //  this.scene.add(result[1]);
            //     //  this.scene.add(result[2]);
            //     //  this.scene.add(result[3]);
            //     //  this.scene.add(result[4]);
            //     //  this.scene.add(result[5]);
            //     //  this.scene.add(result[6]);
            //     //  this.scene.add(result[7]);
            //     //  this.scene.add(result[8]);
            //     //  this.scene.add(result[9]);
            //     //  this.scene.add(result[10]);
            //     //  this.scene.add(result[11]);
            //     //  this.scene.add(result[12]);
            //     //  this.scene.add(result[13]);
            //     //  this.scene.add(result[14]);
            //     //  this.scene.add(result[15]);
            //     //  this.scene.add(result[16]);
            //     //  this.scene.add(result[17]);
            //     //  this.scene.add(result[18]);
            //     //  this.scene.add(result[19]);
            //     //  this.scene.add(result[20]);
            //     //  this.scene.add(result[21]);
            //     //  this.scene.add(result[22]);
            //     //  this.scene.add(result[23]);
            //     //  this.scene.add(result[24]);
            //     //  this.scene.add(result[25]);
            //     //  this.scene.add(result[26]);
            //     //  this.scene.add(result[27]);
            //     //  this.scene.add(result[28]);
            //     //  this.scene.add(result[29]);
            //     //  this.scene.add(result[30]);
            //     //  this.scene.add(result[31]);
            //     //  this.scene.add(result[32]);
            //     //  this.scene.add(result[33]);
    
            //      //this has been loaded, then  increas the counter
            //     //  counter = counter+1;
            //      // console.log("at the end",this.clues.length);
            //      //when you have 10 you are ready to go
            //      // if (this.clues.length==numPieces){
            //      //     resolve(true);
            //      // }
            //      }
                

            //  ).catch(function(error){
            //      console.log("there was an error in the process");

            //  })
             for (var i=0; i<clues.length; i++){

                var filename = this.filepath+"puzzle"+clues[i]+".glb";
                new GameAsset().loadFile(filename)
                .then((result) =>{
                    var puzzlePiece = new GameAsset();
                    puzzlePiece.material.color = new Color(0XFF0000);
                    puzzlePiece.material.depthTest = true;

                    var meshPiece = new THREE.Mesh(result[0].geometry, puzzlePiece.material);
                    meshPiece.visible = true;
                    //set the name
                    puzzlePiece.name = "clue_"+counter.toString();
                    meshPiece.name = "clue_"+counter.toString();
                    meshPiece.position.set(result[0].position.x, result[0].position.y, result[0].position.z);

                    //compute its bounding box
                    puzzlePiece.bbox = new THREE.Box3().setFromObject(meshPiece);
                    puzzlePiece.bbox.getCenter(puzzlePiece.centerModel);
                    // this.clues.push(puzzlePiece);
                    // console.log(this.clues.length);
                    this.scene.add(meshPiece);
                    //this has been loaded, then  increas the counter
                    counter = counter+1;
                    // console.log("at the end",this.clues.length);
                    //when you have 10 you are ready to go
                    // if (this.clues.length==numPieces){
                    //     resolve(true);
                    // }
                }
                

                ).catch(function(error){
                    console.log("there was an error in the process");

                })
            }
            var counter = 0;
            numPieces = 85;
            for (var i=0; i<numPieces; i++){

                var filename = this.filepath+"kundekaricore"+i+".glb";
                new GameAsset().loadFile(filename)
                .then((result) =>{
                    var puzzlePiece = new GameAsset();
                    // const color = new THREE.Color();
                    // console.log(counter);
                    // console.log(this.randomColors[counter]);
                    // puzzlePiece.material.color = color.setHSL(this.randomColors[counter],0.2,0.5);
                    puzzlePiece.material.color = this.getRandomColor(0,1);

                    var meshPiece = new THREE.Mesh(result[0].geometry, puzzlePiece.material);
                    meshPiece.visible = true;
                    //set the name
                    puzzlePiece.name = "clue_"+counter.toString();
                    meshPiece.name = "clue_"+counter.toString();
                    meshPiece.position.set(result[0].position.x, result[0].position.y, result[0].position.z);
                    // meshPiece.visible = false;
                    //compute its bounding box
                    puzzlePiece.bbox = new THREE.Box3().setFromObject(meshPiece);
                    puzzlePiece.bbox.getCenter(puzzlePiece.centerModel);
                    this.clues.push(puzzlePiece);
                    // console.log(this.clues.length);
                    this.scene.add(meshPiece);
                    //this has been loaded, then  increas the counter
                    counter = counter+1;
                    // console.log("at the end",this.clues.length);
                    //when you have 10 you are ready to go
                    if (this.clues.length==numPieces){
                        resolve(true);
                    }
                }
                

                ).catch(function(error){
                    console.log("there was an error in the process");

                })
            }
           
   
        });
      
       
    }

    // setupModelLines(){
    //     // let subset = this.clues.splice(this.clues.length-4);
    //     // let subset = this.clues.splice(9,0);

    //     for (var item of this.clues) {
    //         console.log(item.name);

    //         var mesh = <THREE.Mesh> this.scene.getObjectByName(item.name);
    //         console.log("mesh: ",mesh.position.x, " ",mesh.position.y," ",mesh.position.z);
    //         // mesh.geometry.translate(mesh.position.x, mesh.position.y, mesh.position.z);
    //         mesh.geometry.computeVertexNormals();
    //         mesh.geometry.computeBoundingBox();
    //         mesh.geometry.computeBoundingSphere();
    //         const bbox = mesh.geometry.boundingBox;
    //         const centre = <Vector3> bbox?.getCenter(new Vector3());
    //         const max = <Vector3> bbox?.max;
    //         const min = <Vector3> bbox?.min;

    //         var puzzlePiece = new GameAsset();
    //         puzzlePiece.uniforms.diffuseColor.value = this.getRandomColor(0.1,0.5);
    //         puzzlePiece.uniforms.alpha.value = 1;
    //         puzzlePiece.material.depthTest = true;
    //         puzzlePiece.material.wireframe =  false;
    //         console.log(mesh.geometry.index);
    //         //these measurements gets us the number of vertices
    //         console.log("positions",mesh.geometry.getAttribute('position').count);
    //         console.log("normals",mesh.geometry.getAttribute('normal').count);
    //         var thepositions = mesh.geometry.getAttribute('position');
    //         console.log(thepositions);
    //         const thenormals = mesh.geometry.getAttribute('normal');
    //         const geometry = new THREE.BufferGeometry();


    //         // let ptCout = thepositions.count / 3;
    //         // for (let i = 0; i < ptCout; i++)
    //         // {
    //         //     // let p = new THREE.Vector3(thepositions[i * 3], thepositions[i * 3 + 1], thepositions[i * 3 + 2]);
    //         //     // console.log(p);
    //         // }
    //         // // const indices = [];
    //         // this.controls.enableZoom = true;
    //         // this.controls.maxZoom = 1000;
    //         const vertices = [];
    //         const normals = [];
    //         const colors = [];
    //         //     this.controls.enableZoom = true;
    //         //     // const size = 20;
    //             //     // const segments = 10;

    //             //     // const halfSize = size / 2;
    //             //     // const segmentSize = size / segments;

    //             //     // generate vertices, normals and color data for a simple grid geometry

    //             //     // for ( let i = 0; i <= segments; i ++ ) {

    //             //     // 	const y = ( i * segmentSize ) - halfSize;

    //             //     // 	for ( let j = 0; j <= segments; j ++ ) {

    //             //     // 		const x = ( j * segmentSize ) - halfSize;
    //             var c = this.getRandomColor(0.1,0.5);

    //             const faces = [][3];
    //             // for (let i = 0; i < thepositions.array.length/100; i += (3 * 3)) // we advance 3 by 3 as we will deal with the faces 3 vertices * 3 positions
    //             for (let i = 0; i < thepositions.array.length; i += (3 * 3)) // we advance 3 by 3 as we will deal with the faces 3 vertices * 3 positions
    //             {
    //                 //get the normals total
    //                 var allnormals = new Vector3(thenormals.array[i],thenormals.array[i+1],thenormals.array[i+2]).add(
    //                   new Vector3(thenormals.array[i+3],thenormals.array[i+4],thenormals.array[i+5])
    //                     .add(new Vector3(thenormals.array[i+6],thenormals.array[i+7],thenormals.array[i+8])));
    //                 // console.log(allnormals);
    //                 // console.log(MathUtils.radToDeg( allnormals.angleTo(new Vector3(-centre.x,-centre.y,-centre.z))));
    //                 var angle =MathUtils.radToDeg( allnormals.angleTo(new Vector3(-centre.x,-centre.y,-centre.z)));
    //                 var dot =allnormals.dot(new Vector3(-centre.x,-centre.y,-centre.z));

    //                 if (angle>0&&angle<90){
    //                 // if (dot>=0){
    //                 //     // console.log("dot:",dot);
    //                 //     console.log("angle:",angle);
    //                     //vertices
    //                     //vertex 1
    //                     // vertices.push( thepositions.array[i]);
    //                     // vertices.push( thepositions.array[i+1]);
    //                     // vertices.push( thepositions.array[i+2] );
    //                     vertices.push(0);
    //                     vertices.push(0);
    //                     vertices.push(0);
             

    //                     vertices.push(centre.x);
    //                     vertices.push(centre.y);
    //                     vertices.push(centre.z);

                                   

    //                     vertices.push(mesh.position.x);
    //                     vertices.push(mesh.position.y);
    //                     vertices.push(mesh.position.z);


    //                     // //vertex 2
    //                     // vertices.push( thepositions.array[i+3]);
    //                     // vertices.push( thepositions.array[i+4]);
    //                     // vertices.push( thepositions.array[i+5] );
    //                     // //vertex 3
    //                     // vertices.push( thepositions.array[i+6]);
    //                     // vertices.push( thepositions.array[i+7]);
    //                     // vertices.push( thepositions.array[i+8] );


    //                     // vertices.push(10);
    //                     // vertices.push(0);
    //                     // vertices.push(0);
                        

    //                     // vertices.push(1);
    //                     // vertices.push(1);
    //                     // vertices.push(1);
    //                     // vertices.push(0);
    //                     // vertices.push(0);
    //                     // vertices.push(0);

    //                     // vertices.push(max.x);
    //                     // vertices.push(max.y);
    //                     // vertices.push(max.z);
                        
    //                     //normals
    //                     //normal 1
    //                     normals.push( thenormals.array[i]);
    //                     normals.push( thenormals.array[i+1]);
    //                     normals.push( thenormals.array[i+2]);
    //                     //normal 2
    //                     normals.push( thenormals.array[i+3]);
    //                     normals.push( thenormals.array[i+4]);
    //                     normals.push( thenormals.array[i+5]);
    //                     //normal 3
    //                     normals.push( thenormals.array[i+6]);
    //                     normals.push( thenormals.array[i+7]);
    //                     normals.push( thenormals.array[i+8]);

                
    //                     // if (item.name=='clue_9'){
    //                         colors.push(1.0);
    //                         colors.push(0);
    //                         colors.push(0);
                            
    //                         colors.push(0);
    //                         colors.push(1);
    //                         colors.push(0);

    //                         colors.push(0);
    //                         colors.push(0);
    //                         colors.push(1);
    //                         // for (var j=0; j<9;j++){



    //                     //     // }
    //                     // }else{
    //                     //     for (var j=0; j<9;j++){
    //                     //         // colors.push();
    //                     //         // colors.push(0);
    //                     //         // colors.push(0);
    //                     //         colors.push(c.r,c.g,c.b);
    //                     //     }
    //                     // }

                      
    //                  }

    //             }

    //             //     // 	}

    //             //     // }

    //             //     // generate indices (data for element array buffer)

    //             //     // for ( let i = 0; i < segments; i ++ ) {

    //             //     // 	for ( let j = 0; j < segments; j ++ ) {

    //             //     // 		const a = i * ( segments + 1 ) + ( j + 1 );
    //             //     // 		const b = i * ( segments + 1 ) + j;
    //             //     // 		const c = ( i + 1 ) * ( segments + 1 ) + j;
    //             //     // 		const d = ( i + 1 ) * ( segments + 1 ) + ( j + 1 );

    //             //     // 		// generate two faces (triangles) per iteration

    //             //     // 		indices.push( a, b, d ); // face one
    //             //     // 		indices.push( b, c, d ); // face two

    //             //     // 	}

    //             //     // }

    //             //     //

    //             //     //https://threejsfundamentals.org/threejs/lessons/threejs-custom-buffergeometry.html
    //             //     // geometry.setIndex( indices );
    //             geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
    //             geometry.setAttribute( 'normal', new THREE.Float32BufferAttribute( normals, 3 ) );
    //             geometry.setAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );

    //             const material = new THREE.MeshPhongMaterial( {
    //             side: THREE.DoubleSide,
    //             vertexColors: true
    //             } );


    //             // // // compute the index where the data is stored
    //             // // var index = result[0].geometry.index.array[ 3 * faceIndex + vertexNumber ];

    //             // // // get the vertex normal from the attribute data
    //             // // normal.fromBufferAttribute( geometry.attributes.normal, index );
    //             // // console.log( normal );
    //             // // const normals = result[0].geometry.attributes.normals.array;
    //             // // const positionAttribute = new THREE.Float32BufferAttribute(positions , 3 );
    //             // // console.log("positions "+positions.length);
    //             // // console.log("normals "+normals.length);

    //             var meshPiece = new THREE.Mesh(geometry, material);

    //             // var meshPiece = new THREE.Mesh(result[0].geometry, puzzlePiece.material);
    //             //set the name
    //             puzzlePiece.name = "clueline_"+item.name;
    //             meshPiece.name = "clueline_v"+item.name;
    //             // meshPiece.position.set(mesh.position.x, mesh.position.y, mesh.position.z);

    //             //compute its bounding box
    //             // // puzzlePiece.bbox = new THREE.Box3().setFromObject(meshPiece);
    //             // // puzzlePiece.bbox.getCenter(puzzlePiece.centerModel);
    //             // // this.clues.push(puzzlePiece);
    //             this.scene.add(meshPiece);
    //             //}

    //     // }

    //     } 
      
    // }

    setupPuzzlePieceClue(file : string, name : string, visible: boolean){

        // const material = new THREE.MeshPhongMaterial({
        //     color : new Color(0XFF0000),
        //     opacity: 1,
        //     wireframe : false,
        //     transparent: true,
        //     // side: THREE.DoubleSide,
        //   });
          var piece = new GameAsset();
        //   console.log("asset creatd");

          piece.loadFile(file)
          .then((result) =>{
            // console.log(this.scene);
            //set the material
            // piece.uniforms.diffuseColor.value = new Color(0XFaFaFa);
            // piece.uniforms.alpha.value = 0.4;
            piece.material.color = new Color(0XFaFaFa);
            piece.material.opacity = 0.4;
            // console.log("now has the material");

            var meshPiece = new THREE.Mesh(result[0].geometry, piece.material);
            //set the name
            piece.name = name;
            meshPiece.name = name;
            meshPiece.visible = visible;
            // console.log("asset creatd with position");

            meshPiece.position.set(result[0].position.x, result[0].position.y, result[0].position.z);
            //compute its bounding box
            piece.bbox = new THREE.Box3().setFromObject(meshPiece);
            piece.bbox.getCenter(this.puzzleAsset.centerModel);
            
            // console.log("add to scene");

            this.scene.add(meshPiece);
            // this.pieces.push(piece);


            // piece.distanceCamera = this.hiddenFullPot.distanceCamera;
      
          }
      
          ).catch(function(error){
            console.log("there was an error in the process");
          })
    }

  
}