<!-- <script type="x-shader/x-vertex" id="vertexShader">
  attribute float visible;
  varying float vVisible;
  attribute vec3 vertColor;
  varying vec3 vColor;

  void main() {

    vColor = vertColor;
    vVisible = visible;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

  }
</script>
<script type="x-shader/x-fragment" id="fragmentShader">
  varying float vVisible;
  varying vec3 vColor;

  void main() {

    if ( vVisible > 0.0 ) {

      gl_FragColor = vec4( 1.0, 0.0, 0.0, 1.0 );

    } else {

      discard;

    }

  }
</script> -->
<div #viewer>

  <!-- <div id="slider"> -->
    <!-- <mat-slide-toggle (change)="onInputChange($event)">Give me a clue</mat-slide-toggle> -->

    <!-- Deal with  various objects and their transparency -->
    <!-- Piece -->
    <!-- <div id="slider">
      <br />
      Enable extra clues: &nbsp;&nbsp;<mat-slide-toggle [checked]="this.showPieceHelp"  class="example-margin" (change)="onTogglePieces($event)"></mat-slide-toggle>&nbsp;&nbsp;
      <br/>
      Puzzle piece transparency:<mat-slider 
  #mySlider

  thumbLabel
  tickInterval="1"
  (input)="onInputChangePiece($event)"
  value = {{pieceTransparency}}
  min="0"
  max="10"></mat-slider>  -->
  
  <!-- <div id="slider"> -->
    <!-- <mat-slide-toggle (change)="onInputChange($event)">Give me a clue</mat-slide-toggle> -->

    <!-- Deal with  various objects and their transparency -->
    <!-- All pieces -->
    <!-- <div id="slider">
      All pieces&nbsp;&nbsp;<mat-slide-toggle checked="true"  class="example-margin" (change)="onToggleAllPieces($event)"></mat-slide-toggle>&nbsp;&nbsp;
      Transparency:<mat-slider 
  #mySlider

  thumbLabel
  tickInterval="1"
  (input)="onInputChangeAllPieces($event)"
  value = 10
  min="0"
  max="10"></mat-slider>  -->

  <!--  Pot -->

  <!-- <div id="slider">
    Pot&nbsp;&nbsp;<mat-slide-toggle checked="true" class="example-margin" (change)="onTogglePot($event)"></mat-slide-toggle>&nbsp;&nbsp;
    Transparency:<mat-slider 
  #mySlider

  thumbLabel
  tickInterval="1"
  (input)="onInputChangePot($event)"
  value = "5"
  min="0"
  max="10"></mat-slider> 
  <br/>  -->

  <!-- <div id="slider">
    Core&nbsp;&nbsp;<mat-slide-toggle class="example-margin" (change)="onToggleCore($event)"></mat-slide-toggle> &nbsp;&nbsp;
    Transparency:<mat-slider 
  #mySlider

  thumbLabel
  tickInterval="1"
  (input)="onInputChangeCore($event)"
  value = "5"
  min="0"
  max="10"></mat-slider>  -->
<!-- </div> -->

<canvas id="c1"></canvas>
</div>
