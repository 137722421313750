import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as THREE from 'three';
import { Scene, Color,  Object3D, Vector3, ShaderMaterial, BufferGeometry, MeshStandardMaterial, PerspectiveCamera, UniformsUtils, Vector2
} from 'three';

export class GameAsset {

   public filename : string;
   public name : string;
   public material : MeshStandardMaterial;
  //  public transparency : number;
   public distanceCamera : number;
   public bbox : THREE.Box3;
   public centerModel : THREE.Vector3;
  //  public uniforms = THREE.UniformsUtils.merge([
  //     THREE.UniformsLib[ "common" ],
  //     THREE.UniformsLib[ "fog" ],
  //     THREE.UniformsLib["lights"],
  //     {
  //       "color" : { value: new THREE.Color( 0xFFFFFF ) },
  //       "mode" : { value: 3 },
  //       "Ka" : { value: 0.6 },
  //       "Kd" : { value: 0.8 },
  //       "Ks" : { value: 1.0 },
  //       "shininessVal" : { value: 80.0 },
  //       "ambientColor" : { value: new THREE.Color( 0xFFFFFF ) },
  //       "diffuseColor" : { value: new THREE.Color(0xFFFFFF) },
  //       "specularColor" : { value: new THREE.Color( 0xFFFFFF ) },
  //       "lightPos" : { value: new Vector3(-10.0, -10.0, 100.0) },
  //       "alpha" : {value: 1.0},
  //       "resolution" : {value : new Vector2(0,0)},
  //       "time:" : { value: 1.0 },


  //     }
  //   ]);
  //   static readonly BRIGHTNESS_MAX = 1.0;    // default value. MAX brightness DO not change this
  //                     // DO NOT CHANGE this value to set upper limit
  //                     // Use the const LUX_MAX (see below) to set upper limit
  //   static readonly BRIGHTNESS_MIN = 0.4;  // The darkest you want A value of 0 is black

  //   // add brightness The same uniforms object as you 
  //   // got with uniforms = THREE.UniformsUtils.clone(THREE.UniformsLib["lights"]);
  //   //https://threejs.org/docs/#api/en/renderers/webgl/WebGLProgram
  //   static readonly vertexShader : string = /* glsl */`
  //   // default vertex attributes provided by Geometry and BufferGeometry
  //   //attribute vec3 position;
  //   //attribute vec3 normal;
  //   //attribute vec2 uv;
  //   //uniform mat4 modelMatrix;
  //   //uniform mat4 modelViewMatrix;
  //   //uniform mat4 projectionMatrix;
  //   //uniform mat4 viewMatrix;
  //   //uniform mat3 normalMatrix;
  //   //uniform vec3 cameraPosition;
  
  //   uniform float alpha;
  
  //   varying vec3 normalInterp;
  //   varying vec3 vertPos;
  //   varying float vAlpha;
  
  //   void main(){
  //     // transform a vertex to eye space  - I believe this might be the same than the model matrix
  //     vec4 vertPos4 = modelViewMatrix * vec4(position, 1.0);
  //     vertPos = vec3(vertPos4) / vertPos4.w;
  //     // transform a normal to eye space - I believe this might be the same than the normal matrix
  //     normalInterp = vec3(modelViewMatrix * vec4(normal, 0.0));
  //     vAlpha = alpha;
  
  //     gl_Position = projectionMatrix * vertPos4;
  //   }
  //                 `;
  
  //   static readonly fragmentShader = /* glsl */`
  //   precision mediump float;
  //   varying vec3 normalInterp;  // Surface normal
  //   varying vec3 vertPos;       // Vertex position
  //   uniform int mode;   // Rendering mode
  //   uniform float Ka;   // Ambient reflection coefficient
  //   uniform float Kd;   // Diffuse reflection coefficient
  //   uniform float Ks;   // Specular reflection coefficient
  //   uniform float shininessVal; // Shininess
  //   // Material color
  //   uniform vec3 ambientColor;
  //   uniform vec3 diffuseColor;
  //   uniform vec3 specularColor;
  //   uniform vec3 lightPos; // Light position
  //   uniform float brightness;   // added uniform to control brightness
  //   varying float vAlpha;
  
  //   void main() {
  //     vec3 N = normalize(normalInterp);
  //     vec3 L = normalize(lightPos - vertPos);
  
  //     // Lambert's cosine law
  //     float lambertian = max(dot(N, L), 0.0);
  //     float specular = 0.0;
  //     if(lambertian > 0.0) {
  //       vec3 R = reflect(-L, N);      // Reflected light vector
  //       vec3 V = normalize(-vertPos); // Vector to viewer
  //       // Compute the specular term
  //       float specAngle = max(dot(R, V), 0.0);
  //       specular = pow(specAngle, shininessVal);
  //     }
  //     gl_FragColor = vec4(brightness *(Ka * ambientColor +
  //                         Kd * lambertian * diffuseColor +
  //                         Ks * specular * specularColor), vAlpha);
  
  //     // only ambient
  //     if(mode == 2) gl_FragColor = vec4(Ka * ambientColor, 1.0);
  //     // only diffuse
  //     if(mode == 3) gl_FragColor = vec4(Kd * lambertian * diffuseColor, vAlpha);
  //     // only specular
  //     if(mode == 4) gl_FragColor = vec4(Ks * specular * specularColor, 1.0);
  //   }
  //   `;


   constructor(){
      this.centerModel = new Vector3();

      // this.uniforms.alpha.value = 1.0;
      // this.uniforms.brightness = {value: GameAsset.BRIGHTNESS_MAX};  // default to max
      // this.material  = new THREE.ShaderMaterial( {
      //   uniforms: this.uniforms,
      //   vertexShader : GameAsset.vertexShader,
      //   fragmentShader : GameAsset.fragmentShader,
      //   //use this instead to read shaders from HTML index page
      //   // vertexShader :  document.getElementById( 'vertexshader' ).textContent,
      //       // fragmentShader: document.getElementById( 'fragmentshader' ).textContent
      //   //these are the variables for the double peeling code
      //   // Defines which side of faces will be rendered - front, back or both. 
      //   // Default is THREE.FrontSide. Other options are THREE.BackSide and THREE.DoubleSide.
      //   side: THREE.FrontSide,
      //   depthTest: true,
      //   transparent : true,
      //   // // blending: THREE.CustomBlending,
      //   // // blendEquation : THREE.AddEquation,
      //   // // depthFunc : THREE.LessEqualDepth,

      //   // lights : false,
      //   // transparent: true,
      //   // //blendEquation : THREE.AddEquation,
      //   // blendDst : 1,
      //   // premultipliedAlpha: false  // Ask for non-premultiplied alpha

      // } );
      // console.log("CREATE THE MATERIAL _---- ");
      this.material  = new THREE.MeshStandardMaterial(
        {
          transparent : true,
          depthTest : true,
        
          // color: new THREE.Color(0XFF0000),
        }
      );
      // console.log("created!!!!!!");


   } 
    loadFile(file : string) : Promise<Array<THREE.Mesh>> {

    return new Promise((resolve,reject) =>{
      //add the core part of the puzzle
      if (file) {
        const loader = new GLTFLoader();  
        loader.loadAsync(file)
          .then(data => {
            // console.log(data.scene);
              const model = data.scene.children[0];
              var geometries = new Array<THREE.Mesh>();

              model.traverse(
              //this is a callback function

              ( child :  Object3D) =>  {  
                  if(child instanceof THREE.Mesh){
                    
                    geometries.push(child);
                  }
              });
              //we resolve our promise  to send the child
              if (geometries.length>0)
                resolve(geometries);

          },
          function reject() {

            reject ();
            console.log("not working at the game asset method to load the file");
          } 
          );
        }
    });
   }

    getDistanceCamera( object : THREE.Mesh, fov : number){ 
      // console.log(object);

      // const bbox = new THREE.Box3().setFromObject(object);
      object.geometry.computeBoundingBox();
      let bboxDif : Vector3;
      if (object.geometry.boundingBox){
        this.bbox = object.geometry.boundingBox;
        // const bbox = new THREE.Box3()
        // console.log("BOX £D ",this.bbox);
        if (this.bbox){
        //   console.log("min: "+bbox.min.x +"  " +bbox.min.y +"  " +bbox.min.z +"  " );
        //   console.log("max: "+bbox.max.x +"  " +bbox.max.y +"  " +bbox.max.z +"  " );
          bboxDif = new Vector3(this.bbox.max.x - this.bbox.min.x,
                                  this.bbox.max.y - this.bbox.min.y,
                                  this.bbox.max.z - this.bbox.min.z);
      
                                      
          
          // var dif = new Vector2((bbox.max.x - bbox.min.x),(bbox.max.y - bbox.min.y));
        //   console.log("dif: "+bboxDif.x +"  " +bboxDif.y +"  "  );
          // console.log(this.bboxDif.length());
          // console.log(this.bbox.getSize.length);
        }
      }
      return bboxDif!.length() / (2 *Math.tan( (fov/2)  * (Math.PI/180)  ));
      // console.log("dist: "+this.dist);
      // this.camera.position.set(0,0,this.dist);
  
    }
    // setTransparency(transparency : number){
    //   // this.material.uniforms.
    // }
  
}
    