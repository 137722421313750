<!-- <div cdkDrop (cdkDropDropped)="test()" > -->
    <!-- <div class="example-container">
        <h2>Done</h2>
      
        <div
          cdkDropList
          #doneList="cdkDropList"
          [cdkDropListData]="done"
          [cdkDropListConnectedTo]="[todoList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)"> -->
          <!-- <div class="example-box" *ngFor="let item of done" cdkDrag>{{item}}</div> -->
          <!-- <div class="example-box" *ngFor="let item of done" cdkDrag><puzzle-piece [item]="item"></puzzle-piece></div>  -->
          <!-- <table class="tableResizable">
            <tr>
                <th *ngFor="let item of done">
                    <div cdkDrag (cdkDragReleased)="started($event)">
                    <puzzle-piece [item]="item"></puzzle-piece>
                    </div>
                </th>
              </tr>	  
            
            </table> 
        </div>
      </div>


    <div class="example-container">
        <h2>To do</h2>
      
        <div
          cdkDropList
          #todoList="cdkDropList"
          [cdkDropListData]="todo"
          [cdkDropListConnectedTo]="[doneList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)"> -->
          <!-- <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item}}</div> -->
          <!-- <table class="tableResizable">
            <tr>
                <th *ngFor="let item of todo">
                    <div cdkDrag (cdkDragReleased)="started($event)">
                    <puzzle-piece [item]="item"></puzzle-piece>
                    </div>
                </th>
              </tr>	  
            
            </table>  -->
          <!-- <div class="example-box" *ngFor="let item of todo" cdkDrag><puzzle-piece [item]="item"></puzzle-piece></div>  -->
<!-- 
        </div>
      </div>

         <div cdkDrop > -->

    <!-- <mat-card class="main-puzzle-viewer">
        <mat-card-content>     -->
            <!-- <div  class="main-puzzle-viewer" > -->
              <!-- {{item}} -->
                <div #viewer></div>
            <!-- </div>  -->
        <!-- </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
    </mat-card>   -->
<!-- </div> -->

<!-- </div> -->
<!-- <div cdkDrop (cdkDragStarted)="started($event)"> -->
    <!-- <div cdkDrop   [cdkDropConnectedTo]="[active]" > -->
        <!-- <puzzle-piece [item]="currentFile" cdkDrag (cdkDragReleased)="started($event)"></puzzle-piece> -->
    <!-- </div> -->
  <!-- </div> -->
    <!-- <div cdkDrop>
        <div cdkDrag (cdkDragReleased)="started($event)">
        <puzzle-piece [item]="currentFile" ></puzzle-piece>
        </div>
    </div> -->
    <!-- <div cdkDropList class="puzzleDiv" (cdkDropListDropped)="dropped($event)"> -->
    <!-- <div cdkDrop class="puzzleDiv" (cdkDropDropped)="dropped($event)">

    <table class="tableResizable">
		<tr>
			<th *ngFor="let item of items">
                <div cdkDrag (cdkDragReleased)="started($event)">
				<puzzle-piece [item]="item"></puzzle-piece>
                </div>
			</th>
		  </tr>	  
		
        </table> 
    </div> -->


    


      


	<!-- <puzzle-piece [item]="currentFile" (newItemEvent)="bllll($event)" (cdkDragDropped)="bllll($event)"></puzzle-piece>
	<puzzle-piece *ngFor="let item of items; index as i;" [item]="i"></puzzle-piece> -->