// To deploy in wordpress as a plugin:
// https://dev.to/stevewhitmore/take-your-wordpress-site-farther-with-angular-3o6p
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ViewGLComponent } from './view-gl/view-gl.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import { PuzzleCoreComponent } from './puzzle-core/puzzle-core.component';
// import { AngularResizedEventModule } from 'angular-resize-event';
import {DragDropModule } from '@angular/cdk/drag-drop';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIcon, MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatBadgeModule} from '@angular/material/badge';
import {TextureplaneComponent} from './textureplane/textureplane.component';
import { NgxTimerModule } from 'ngx-timer';
import {APP_BASE_HREF} from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    ViewGLComponent,
    PuzzleCoreComponent,
    TextureplaneComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatButtonModule,
    MatDividerModule,
    MatCardModule,
    DragDropModule,
    MatGridListModule,
    MatToolbarModule,
    MatBadgeModule,
    MatSlideToggleModule,
    NgxTimerModule,
    MatIconModule,

    AngularFireModule.initializeApp(environment.firebase),

    // Specify AngularResizedEventModule library as an import
    // AngularResizedEventModule
  ],
  providers: [{provide: APP_BASE_HREF, useValue: ''}],
  bootstrap: [AppComponent]
})
export class AppModule { }
