import { Component, OnInit, ViewChild, Input, HostListener, OnChanges } from '@angular/core';
import * as THREE from 'three';
import { Vector2, Vector3, WebGLRenderer, Object3D, Mesh, Box3, MeshMatcapMaterial,WebGLRenderTarget,
  MeshPhongMaterial } from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'puzzle-core',
  templateUrl: './puzzle-core.component.html',
  styleUrls: ['./puzzle-core.component.css']
})


export class PuzzleCoreComponent implements OnInit, OnChanges {
  
  width: number;
  height: number;
  // breakpoint = 1;
  @ViewChild('viewer') title : any;
  @Input() mainfile!: string; // decorate the property with @Input()
  @Input() item!: string; // decorate the property with @Input()
  @Input() colorViewer!: string; // decorate the property with @Input()
  // @HostListener('window:resize')

  camera : THREE.PerspectiveCamera ;
  rtCamera : THREE.PerspectiveCamera ;
  scene: THREE.Scene;
  rtScene : THREE.Scene;
  renderer: THREE.WebGLRenderer = new THREE.WebGLRenderer;
  // renderer2: THREE.WebGLRenderer;
  loader: GLTFLoader ;
  controls : OrbitControls;
  model : Object3D;
  pieces : Array<Object3D> = [];
  meshPiece : Mesh;
  meshCore : Mesh;
  meshPlane : Mesh
  bboxDif : Vector3;
  bbox : Box3;
  dist : number;
  canvas1 : any;
  // canvas2 : any;
  ctxgl : WebGLRenderingContext;
  readonly fov = 60;
  readonly aspect = window.innerHeight*1.5/window.innerHeight;  // the canvas default
  readonly near = 0.01;
  readonly far = 10000;
  //ray caster
  raycaster = new THREE.Raycaster();
  mouse = new THREE.Vector2();
  //     mouse_x = 0;
  // mouse_y = 0;
  mymaterial = new MeshPhongMaterial;


  renderTarget : WebGLRenderTarget;

  ngOnInit(): void {
    this.width = window.innerHeight/1;
    this.height = window.innerHeight/1.5;
    // console.log("mx + "+ this.mouse.x);
    this.canvas1  = document.createElement( 'canvas' );
    this.canvas1.setAttribute("id", "myCanvas");
    this.ctxgl = this.canvas1.getContext('webgl');
    if (!this.ctxgl) {
      // browser supports WebGL but initialization failed.
      console.log("I am broken");
    }else{
      console.log("YEIII not broken");

    }
    this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas: this.canvas1, alpha: false });
    this.renderer.setSize( this.width , this.height );
    // this.renderer.setClearColor("#4D5061");
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableZoom = false;


  }
  
  ngAfterViewInit() : void {
    // console.log("mx + "+ this.mouse.x);

    // console.log(this.item);
    // console.log("*****this.item " +this.item);
    if (this.mainfile) {
      this.loadFile(this.mainfile);
      // this.setPlaneViewport();

    }
    // if (this.item) this.loadFile(this.item);
    // console.log(this.colorViewer);
    this.renderer.setClearColor(this.colorViewer);   // var divElement;
    // divElement.style.margin="100px auto";
    // divElement.appendChild(paragraph);
    // var divElement = document.createElement("div");

    // this.title.nativeElement.appendChild( this.renderer.domElement  );
    
    // this.renderer.setSize(this.title.nativeElement.offsetWidth, window.innerHeight, false);

    this.title.nativeElement.appendChild( this.renderer.domElement  );
    // this.title.nativeElement.appendChild( this.renderer2.domElement  );
    // var rootElement = this.title.nativeElement;
    // var childElement = rootElement.firstElementChild;
    // var contentElement =  childElement.firstElementChild;
    // console.log("22"+childElement.clientWidth);
    // console.log("22"+this.title.nativeElement.offsetWidth);
    // this.renderer.setSize(100, 20, false);

    // height:contentElement.clientHeight,
        // width: contentElement.clientWidth
    this.render();

   

}
  constructor() { 
    //main viewer
    this.width = window.innerHeight/1;
    this.height = window.innerHeight/1.5;
    this.model = new Object3D();
    this.meshPiece = new THREE.Mesh();
    this.meshCore = new THREE.Mesh();
    this.meshPlane = new THREE.Mesh();
    // this.container  = document.createElement( 'div' );
    this.scene = new THREE.Scene();
    this.bboxDif  = new Vector3();
    this.bbox = new Box3();
    this.dist = 0;
    // keep this to initialise canvas
    // this.canvas1  = document.createElement( 'canvas' );
    // this.canvas1.setAttribute("id", "myCanvas");

    // canvas1.width = 10;
    // canvas1.height = 10;
    // canvas1.style.border = "10px solid red";
    // canvas1.style.margin = "10px auto";
    // canvas1.style.background =" background-color: #eed";
  // var ctx = canvas1.getContext('webgl');
  // if (ctx!=null){
  //   console.log("1111");
  //   console.log(ctx);
  //   ctx.enable(ctx.DEPTH_TEST);
  //   ctx.clearColor(0.1, 0.5, 0.1, 1.0);
  //   ctx.blendColor(0.1, 0.5, 0.1, 1.0);
  //   ctx.clear(ctx.DEPTH_BUFFER_BIT | ctx.COLOR_BUFFER_BIT);
  // }
  //gets the context
    // this.ctxgl = this.canvas1.getContext('webgl');
    // if (!this.ctxgl) {
    //   // browser supports WebGL but initialization failed.
    //   console.log("I am broken");
    // }else{
    //   console.log("YEIII not broken");

    // }
    // this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas: this.canvas1, alpha: false });

    //initialise loader
    this.loader = new GLTFLoader();

    //initialise the renderer
    // this.renderer.setSize( this.width , this.height );
    // // this.renderer.setClearColor("#4D5061");
    // this.renderer.outputEncoding = THREE.sRGBEncoding;

    //define camera
    this.camera = new THREE.PerspectiveCamera( this.fov, this.aspect, this.near, this.far);
    // this.camera.position.set( 0, 2, 30 );
    this.camera.lookAt(this.scene.position);
    this.scene.add( this.camera );

 
    //define controls
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    // this.controls.enableZoom = false;
    // this.controls.target.set(0, 5, 0);

    //define light
    const color = 0xFFFFFF;
    const intensity = 0.5;
    const ambientlight = new THREE.AmbientLight(color, intensity);
    this.camera.add( ambientlight );

    const directionallight = new THREE.DirectionalLight(color, intensity);
    // directionallight.position.set( 0, 2, 100 );
    directionallight.target.position.set(0, 0, 0);
    // this.scene.add(light1);
    // this.scene.add(light);
    // this.scene.add(light.target);
    const helper = new THREE.DirectionalLightHelper(directionallight);
    // this.scene.add(helper);
    this.camera.add( directionallight );
    // this.camera.add( light.target );

    // var pointLight = new THREE.PointLight(0xffffff, 10, 40);
    // pointLight.position.set(50, 50, 76);
    // var lightHolder = new THREE.Group();
    // lightHolder.add(pointLight);
    // this.scene.add(lightHolder);
    // const spotLight = new THREE.SpotLight( 0xffffff );
    // spotLight.position.set( 0, 500, 500  );

    // spotLight.castShadow = true;

    // spotLight.shadow.mapSize.width = 2048;
    // spotLight.shadow.mapSize.height = 2048;

    // spotLight.shadow.camera.near = 5000;
    // spotLight.shadow.camera.far = 40000;
    // spotLight.shadow.camera.fov = 60;

    // this.scene.add( spotLight );


    // const gui = new dat.GUI();
    // gui.addColor(new ColorGUIHelper(light, 'color'), 'value').name('color');
    // gui.add(light, 'intensity', 0, 2);
    //setup raycaster


     
    // this.scene.add( this.plane );

    // this.raycaster = new THREE.Raycaster();
    // this.renderer.domElement.addEventListener( 'click', event => {
    // // calculate mouse position in normalized device coordinates
    // // (-1 to +1) for both components
    //   this.mouse.x = ( event.clientX / this.width ) * 2 - 1;
    //   this.mouse.y = - ( event.clientY / this.height ) * 2 + 1;
    //   console.log(this.mouse.x  + "   " + this.mouse.y);
    // });



    //*********secondary canvas rendering the individual piece *********

    // this.canvas2  = document.createElement( 'canvas' );
    // this.canvas2.setAttribute("id", "myCanvas2");

    // this.renderer2 = new THREE.WebGLRenderer({ antialias: true, canvas: this.canvas2, alpha: false });
    const rtWidth = 512;
    const rtHeight = 512;
    this.renderTarget = new THREE.WebGLRenderTarget(rtWidth, rtHeight);
  
    const rtFov = 75;
    const rtAspect = rtWidth / rtHeight;
    const rtNear = 0.1;
    const rtFar = 5;
    this.rtCamera = new THREE.PerspectiveCamera(rtFov, rtAspect, rtNear, rtFar);
    this.rtCamera.position.z = 2;
  
    this.rtScene = new THREE.Scene();
    this.rtScene.background = new THREE.Color('red');
    {
      const color = 0xFFFFFF;
      const intensity = 1;
      const light = new THREE.DirectionalLight(color, intensity);
      light.position.set(-1, 2, 4);
      this.rtScene.add(light);
    }
    const boxWidth = 1;
    const boxHeight = 1;
    const boxDepth = 1;
    const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);
  
    const rtCubes = [
      this.makeInstance(geometry, 0x44aa88,  0),
      this.makeInstance(geometry, 0x8844aa, -2),
      this.makeInstance(geometry, 0xaa8844,  2),
    ];
    var geo1 = new THREE.PlaneGeometry( 100, 100, 1 );
    // });
  this.mymaterial = new THREE.MeshPhongMaterial({
    map: this.renderTarget.texture,
  });   
  // console.log(this.mymaterial.map?.isTexture);
  var me1 = new THREE.Mesh( geo1, this.mymaterial  );
  this.scene.add(me1);

  }
makeInstance(geometry :any, color : any, x : any) {
    const material = new THREE.MeshPhongMaterial({color});

    const cube = new THREE.Mesh(geometry, material);
    this.rtScene.add(cube);

    cube.position.x = x;

    return cube;
}
protected loadPiece(toload : string){
    console.log("load piece "+toload);
    this.loader.loadAsync(toload)
    
    // this.loader.loadAsync('/assets/puzzle1/puzzleall.glb')
    .then(data => {
      
      console.log(data);
      this.model = data.scene.children[0];
       
      this.model.traverse(
        
        //this is a callback function
         ( child ) =>  {
        let self = this;

        if(child instanceof THREE.Mesh){
            // child.materials = [weiss, rot];
            child.geometry.computeVertexNormals();
            //const m1 = new THREE.MeshBasicMaterial({color: 0xFF0000});         // red
            const color = 0xFF0000;
            const material = new THREE.MeshPhongMaterial({
              color,
              opacity: 1,
              transparent: false,
            });
            this.meshPiece = new THREE.Mesh(child.geometry, material);
            // this.scene.add(this.meshPiece);
            this.scene.add(this.meshPiece);
        }

        }
      );


      // this.model.customDepthMaterial(material);
      // const cube = new THREE.Mesh(this.model., material);

      //this.scene.add(this.model);

      

      }, 
      function reject() {
        console.log("there was an error loading")
      } 
    );
  }

  protected loadFile(toload : string){
    // console.log("mx + "+ this.mouse.x);

    // console.log(toload);
   

    this.loader.loadAsync(this.mainfile)
    // this.loader.loadAsync('/assets/puzzle1/puzzleall.glb')
    .then(data => {
      
      console.log(data);
      this.model = data.scene.children[0];
      // this.model.position.set(0, 0, 0);
      // this.model.scale.set(3,3,3);
      // console.log("mypos"+this.model.position);

      this.model.traverse(
        
        //this is a callback function
         ( child ) =>  {
        let self = this;

        if(child instanceof THREE.Mesh){
            // child.materials = [weiss, rot];
            child.geometry.computeVertexNormals();
            //const m1 = new THREE.MeshBasicMaterial({color: 0xFF0000});         // red
            const color = 0xFFFFFF;
            const material = new THREE.MeshPhongMaterial({
              color,
              opacity: 0.9,
              transparent: false,
            });
            this.meshCore = new THREE.Mesh(child.geometry, material);
            this.scene.add(this.meshCore);
        }

        }
      );
      // const box = new THREE.Box3();

      this.meshCore.geometry.computeBoundingBox();
      this.bbox = new THREE.Box3().setFromObject(this.meshCore);
      console.log("min: "+this.bbox.min.x +"  " +this.bbox.min.y +"  " +this.bbox.min.z +"  " );
      console.log("max: "+this.bbox.max.x +"  " +this.bbox.max.y +"  " +this.bbox.max.z +"  " );
      this.bboxDif.setX(this.bbox.max.x - this.bbox.min.x);
      this.bboxDif.setY(this.bbox.max.y - this.bbox.min.y);
      this.bboxDif.setZ(this.bbox.max.z - this.bbox.min.z);
      // var dif = new Vector2((bbox.max.x - bbox.min.x),(bbox.max.y - bbox.min.y));
      console.log("dif: "+this.bboxDif.x +"  " +this.bboxDif.y +"  "  );
      console.log(this.bboxDif.length());
      console.log(this.bbox.getSize.length);
      this.dist = this.bboxDif.length() / (2 *Math.tan( (75/2)  * (Math.PI/180)  ));
      console.log("dist: "+this.dist);
      this.camera.position.set(0,0,this.dist);
      // console.log(bbox.min.x +",  "+bbox.min.y +",  "+bbox.min.z +",  ");
      // console.log(bbox.max.x +",  "+bbox.max.y +",  "+bbox.max.z +",  ");
      // console.log(bbox.getCenter);
      // console.log(bbox.getSize.length);
      // console.log(dist);
      // this.meshPlane.translateZ(dist-1);

      // const m1 = new THREE.MeshBasicMaterial({color: 0xFF0000});         // red
     // this.setPlaneViewport();
      


      // this.model.customDepthMaterial(material);
      // const cube = new THREE.Mesh(this.model., material);

      // this.scene.add(this.model);

      

      }, 
      function reject() {
        console.log("there was an error loading")
      } 
    );
    
  }
  setPlaneViewport(){
      //set plane
      // const canvas = this.renderer.domElement;
      // this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
  
      var geometry = new THREE.PlaneGeometry( this.bboxDif.length(), this.bboxDif.length(), 1 );
      // var material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
  
  
      const material = new THREE.MeshPhongMaterial({
//        color: 0xffff00,
        color: 0x0000FF,
        opacity: 0.2,
        transparent: true,
        side: THREE.DoubleSide
      });
      this.meshPlane = new THREE.Mesh( geometry, material );
  
      
      // var target = new THREE.Object3D();
      // //position
      // target.position.copy(this.camera.position);
      // target.position.add(THREE.Utils.cameraLookDir(this.camera).multiplyScalar(300));
      // //rotation
      // target.rotation.copy(this.camera.rotation);
      // target.rotation.y += Math.PI;
      // target.rotation.z = -this.camera.rotation.z;
      // if (this.camera.position.z < 0) {
      //     target.rotation.z = this.camera.rotation.z;
      // }
      this.meshPlane.lookAt( this.camera.position );
      this.camera.updateMatrixWorld();
      
      
      // -this.camera.position.x,this.camera.lookAt().x-this.camera.position.x;
      var vect = new Vector3();
      // vect.project(this.camera)
      // this.camera.position.x-this.bbox.getCenter();
      // var a = ;
      console.log("z to get " + (this.scene.position.z-this.camera.position.z));
      console.log("z to get " + this.dist);
      this.meshPlane.geometry.translate(
        0,
        0,
        -1);
      //this.camera.add(this.meshPlane);
      // instantiate a loader

      //load texture
      const loader = new THREE.TextureLoader();

      // load a resource
      var thematerial = loader.load(
      // resource URL
      'assets/uv.jpg',

      // onLoad callback
      function ( texture ) : THREE.MeshBasicMaterial {
        // in this example we create the material when the texture is loaded
        var mmm = new THREE.MeshBasicMaterial( {
          map: texture
          } );
        return mmm;
      },

      // onProgress callback currently not supported
      // undefined,

      // onError callback
      function ( err ) {
        console.error( 'An error happened.' );
      }
      );

      // var mymaterial = new THREE.MeshBasicMaterial({
      //   map: this.renderTarget.texture,


      var geo1 = new THREE.PlaneGeometry( this.bboxDif.length(), this.bboxDif.length(), 1 );
        // });
      var mymaterial = new THREE.MeshPhongMaterial({
        map: this.renderTarget.texture,
      });    // var geo1 = new THREE.PlaneGeometry( 100, 100, 1 );

      // var material1 = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide} );
      // var material3 = new THREE.MeshBasicMaterial( { map: thematerial }  );

      // this.getTexture();
  //   //   const material2 = new THREE.ShaderMaterial({
  //   //     uniforms: { 
  //   //       color: { value: new THREE.Color( 0xffff00) },
  //   //       texture: { value: thematerial },
  //   //       viewMatrixCamera: { value: this.camera.matrixWorldInverse.clone() },
  //   //       projectionMatrixCamera: { value: this.camera.projectionMatrix.clone() },
  //   //       modelMatrixCamera: { value: this.camera.matrixWorld.clone() },
  //   //       projPosition: {  value: this.camera.position.clone() },
  
  //   //     },
  //   //     vertexShader: `
  //   //     uniform mat4 viewMatrixCamera;
  //   //     uniform mat4 projectionMatrixCamera;
  //   //     uniform mat4 modelMatrixCamera;

  //   //     varying vec4 vWorldPosition;
  //   //     varying vec3 vNormal;
  //   //     varying vec4 vTexCoords;


  //   //     void main() {
  //   //       vNormal = mat3(modelMatrix) * normal;
  //   //       vWorldPosition = modelMatrix * vec4(position, 1.0);
  //   //       vTexCoords = projectionMatrixCamera * viewMatrixCamera * vWorldPosition;
  //   //       gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  //   //     }
  //   //   `,

  //   // fragmentShader: `
  //   //   uniform vec3 color;
  //   //   uniform sampler2D texture;
  //   //   uniform vec3 projPosition;

  //   //   varying vec3 vNormal;
  //   //   varying vec4 vWorldPosition;
  //   //   varying vec4 vTexCoords;
      
  //   //   void main() {
  //   //     vec2 uv = (vTexCoords.xy / vTexCoords.w) * 0.5 + 0.5;

  //   //     vec4 outColor = texture2D(texture, uv);

  //   //     // this makes sure we don't render the texture also on the back of the object
  //   //     vec3 projectorDirection = normalize(projPosition - vWorldPosition.xyz);
  //   //     float dotProduct = dot(vNormal, projectorDirection);
  //   //     if (dotProduct < 0.0) {
  //   //       outColor = vec4(color, 1.0);
  //   //     }

  //   //     gl_FragColor = outColor;
  //   //   }
  //   // `,

  //   //   });

  //   //   let uniforms = {
  //   //     // colorB: {type: 'vec3', value: new THREE.Color(0xFFFF00)},
  //   //     // colorA: {type: 'vec3', value: new THREE.Color(0x0000FF)},
  //   //   texture: { type: 't', value: thematerial }, 
  //   //   uvOffset : { type : 'v', value : new THREE.Vector2(0,0) } 

  //   // }

  //   let uniforms = THREE.UniformsUtils.merge([
  //     THREE.UniformsLib.common,
  //     THREE.UniformsLib.specularmap,
  //     THREE.UniformsLib.envmap,
  //     THREE.UniformsLib.aomap,
  //     THREE.UniformsLib.lightmap,
  //     THREE.UniformsLib.emissivemap,
  //     THREE.UniformsLib.bumpmap,
  //     THREE.UniformsLib.normalmap,
  //     THREE.UniformsLib.displacementmap,
  //     THREE.UniformsLib.gradientmap,
  //     THREE.UniformsLib.fog,
  //     THREE.UniformsLib.lights,
  //     {
  //         // custom uniforms:
  //         // diffuse: { value: new THREE.Color(color) },
  //         // emissive: { value: new THREE.Color(emissive) },
  //         // specular: { value: new THREE.Color(specular) },
  //         // shininess: { value: shininess }
  //         map : {value: thematerial},
  //          texture: { type: 't', value: thematerial }, 
  //          uvOffset : { type : 'v', value : new THREE.Vector2(0,0) } 
  //     }
  // ]);

    // var canv =  <HTMLCanvasElement> document.getElementById('myCanvas');
    // console.log(canv);
    // var textureit : any;
    // var ctx : any;
      
    //   if (canv){
    //     ctx = canv.getContext('webgl');
    //     if (!ctx) {
    //       // browser supports WebGL but initialization failed.
    //       console.log("I am broken");
    //     }
    //     console.log(ctx);
    //     ctx.canvas; // HTMLCanvasElement
    //     console.log(ctx);
        // var textureit = this.loadTexture(this.ctxgl, 'assets/uv.jpg');
        // const material7 = new THREE.ShaderMaterial({
        //         uniforms: { 
        //           texture: { value: textureit },
        //         },
        //         fragmentShader: this.fragmentShader(),
        //         vertexShader: this.vertexShader(),
        //       });

  //     const material4 = new THREE.ShaderMaterial( {
  //       uniforms: uniforms,
  //       fragmentShader: this.fragmentShader(),
  //       vertexShader: this.vertexShader(),
  //       // defines: {
  //       //   "STANDARD": '',
  //       //   "USE_UV": '',
  //       //   "USE_MAP": ''
  //       // },
  //     }
  //     );
      // Load texture
      var me1 = new THREE.Mesh( geo1, mymaterial );
      this.scene.add(me1);
      // this.meshPlane.position.set(0,0,this.camera.position.z-1);
      // this.meshPlane.position.set(0,0,10);

  }
// getTexture(){


  // const rtWidth = 512;
  // const rtHeight = 512;
  // const renderTarget = new THREE.WebGLRenderTarget(rtWidth, rtHeight);

  // const rtFov = 75;
  // const rtAspect = rtWidth / rtHeight;
  // const rtNear = 0.1;
  // const rtFar = 5;
  // const rtCamera = new THREE.PerspectiveCamera(rtFov, rtAspect, rtNear, rtFar);
  // rtCamera.position.z = 2;

  // const rtScene = new THREE.Scene();
  // rtScene.background = new THREE.Color('red');

  // {
  //   const color = 0xFFFFFF;
  //   const intensity = 1;
  //   const light = new THREE.DirectionalLight(color, intensity);
  //   light.position.set(-1, 2, 4);
  //   rtScene.add(light);
  // }

  // const boxWidth = 1;
  // const boxHeight = 1;
  // const boxDepth = 1;
  // const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);

  // function makeInstance(geometry :any, color:any, x:any) {
  //   const material = new THREE.MeshPhongMaterial({color});

  //   const cube = new THREE.Mesh(geometry, material);
  //   rtScene.add(cube);

  //   cube.position.x = x;

  //   return cube;
  // }

  // const rtCubes = [
  //   makeInstance(geometry, 0x44aa88,  0),
  //   makeInstance(geometry, 0x8844aa, -2),
  //   makeInstance(geometry, 0xaa8844,  2),
  // ];

  // const fov = 75;
  // const aspect = 2;  // the canvas default
  // const near = 0.1;
  // const far = 5;
  // const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
  // camera.position.z = 2;

  // const scene = new THREE.Scene();

  // {
  //   const color = 0xFFFFFF;
  //   const intensity = 1;
  //   const light = new THREE.DirectionalLight(color, intensity);
  //   light.position.set(-1, 2, 4);
  //   scene.add(light);
  // }
  // const material = new THREE.MeshPhongMaterial({
  //   map: renderTarget.texture,
  // });
  // const cube = new THREE.Mesh(geometry, material);
  // scene.add(cube);

  // function resizeRendererToDisplaySize(renderer: any) {
  //   const canvas = renderer.domElement;
  //   const width = canvas.clientWidth;
  //   const height = canvas.clientHeight;
  //   const needResize = canvas.width !== width || canvas.height !== height;
  //   if (needResize) {
  //     renderer.setSize(width, height, false);
  //   }
  //   return needResize;
  // }

  // function render(time :any) {
  //   time *= 0.001;

  //   // if (resizeRendererToDisplaySize(renderer)) {
  //   //   const canvas = renderer.domElement;
  //   //   camera.aspect = canvas.clientWidth / canvas.clientHeight;
  //   //   camera.updateProjectionMatrix();
  //   // }

  //   // rotate all the cubes in the render target scene
  //   rtCubes.forEach((cube, ndx) => {
  //     const speed = 1 + ndx * .1;
  //     const rot = time * speed;
  //     cube.rotation.x = rot;
  //     cube.rotation.y = rot;
  //   });

  //   // draw render target scene to render target
  //   renderer.setRenderTarget(renderTarget);
  //   renderer.render(rtScene, rtCamera);
  //   renderer.setRenderTarget(null);

  //   // rotate the cube in the scene
  //   // cube.rotation.x = time;
  //   // cube.rotation.y = time * 1.1;

  //   // render the scene to the canvas
  //   // renderer.render(scene, camera);

  //   // requestAnimationFrame(render);
  // }

  // requestAnimationFrame(render);
// }

//   loadTexture(gl : any, url : any) {
//     const texture = gl.createTexture();
//     // gl.bindTexture(gl.TEXTURE_2D, texture);

//     // // Because images have to be downloaded over the internet
//     // // they might take a moment until they are ready.
//     // // Until then put a single pixel in the texture so we can
//     // // use it immediately. When the image has finished downloading
//     // // we'll update the texture with the contents of the image.
//     // const level = 0;
//     // const internalFormat = gl.RGBA;
//     // const width = 1;
//     // const height = 1;
//     // const border = 0;
//     // const srcFormat = gl.RGBA;
//     // const srcType = gl.UNSIGNED_BYTE;
//     // const pixel = new Uint8Array([0, 0, 255, 255]);  // opaque blue
//     // gl.texImage2D(gl.TEXTURE_2D, level, internalFormat,
//     //               width, height, border, srcFormat, srcType,
//     //               pixel);

//     // const image = new Image();
    
//     // image.onload = event => this.toDoOnload(event, gl, texture, image, level,
//     //   internalFormat, srcFormat);    
//     // // function() {
//     // //   this.toDoOnload(gl, texture, image, level,internalFormat, srcFormat, srcType)};
//     // // }
    
//     // // function() {
      
      
//     // //   this.toDoOnload(gl, texture, image, level,internalFormat, srcFormat, srcType);
//     // image.src = url;

//     return texture;
// }
// toDoOnload(gl : any , texture : any , image : any , level : any ,
//   internalFormat : any , srcFormat : any , srcType : any ) {
//   gl.bindTexture(gl.TEXTURE_2D, texture);
//   gl.texImage2D(gl.TEXTURE_2D, level, internalFormat,
//                 srcFormat, srcType, image);

//   // WebGL1 has different requirements for power of 2 images
//   // vs non power of 2 images so check if the image is a
//   // power of 2 in both dimensions.
//   if (this.isPowerOf2(image.width) && this.isPowerOf2(image.height)) {
//       // Yes, it's a power of 2. Generate mips.
//       gl.generateMipmap(gl.TEXTURE_2D);
//   } else {
//       // No, it's not a power of 2. Turn off mips and set
//       // wrapping to clamp to edge
//       gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
//       gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
//       gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
//   }
// }
// isPowerOf2(value: any) {
//     return (value & (value - 1)) == 0;
// }
// default values: camera.projectionMatrix
// uniform mat4 projectionMatrix;
// default values: camera.matrixWorldInverse * object.matrixWorld
//uniform mat4 modelViewMatrix;
// default vertex attributes provided by Geometry and BufferGeometry
// attribute vec3 position;
// attribute vec3 normal;
// attribute vec2 uv;

// vertexShader() {
//     return `
//     attribute vec4 aVertexPosition;
//     attribute vec2 aTextureCoord;

//     uniform mat4 uModelViewMatrix;
//     uniform mat4 uProjectionMatrix;

//     varying highp vec2 vTextureCoord;

//     void main(void) {
//       gl_Position = uProjectionMatrix * uModelViewMatrix * aVertexPosition;
//       vTextureCoord = aTextureCoord;
//     }

//     `
//     // varying vec2 vUv;

//     // void main( void ) {
//     //  vUv = uv;
//     //  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
//     //  }
//       // varying vec3 vUv; 
  
//       // void main() {
//       //   vUv = position; 
  
//       //   vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
//       //   gl_Position = projectionMatrix * modelViewPosition; 
//       // }
//   }
//   fragmentShader(){
//     return `
//     varying highp vec2 vTextureCoord;

//     uniform sampler2D uSampler;

//     void main(void) {
//       gl_FragColor = texture2D(uSampler, vTextureCoord);
//     }
//   `
//   // uniform vec2 uvOffset;
//   // uniform sampler2D texture;
//   // varying vec2 vUv;

//   // void main() {
//   //   gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
//   // }
//   //texture2D(texture, uv);
//   // vec4(1.0, 0.0, 0.0, 1.0);
//   // uniform vec3 colorA; 
//   // uniform vec3 colorB; 
//   // varying vec3 vUv;

//   // void main() {
//   //   gl_FragColor = vec4(mix(colorA, colorB, vUv.z), 1.0);
//   // }

//   }
  onMouseMove( event: MouseEvent ) {

    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    // console.log("here");
    // console.log("click" + event.clientX+ " "+ event.clientY)
    // console.log(this.width + " " + this.height)
    // console.log(window.innerWidth + " "+ window.innerHeight);
    // console.log(window.innerWidth + " "+ window.innerHeight);
    this.mouse.x = ( event.clientX / this.width ) * 2 - 1;
    this.mouse.setY( - ( event.clientY / this.height ) * 2 + 1);

    // console.log("mcord "+ this.mouse.x + " - " + this.mouse.y)
  
  }

 
  ngOnDestroy(){
    
  }
// resizeRendererToDisplaySize(renderer : any) {
//     // const canvas = renderer.domElement;
//     // const width = canvas.clientWidth;
//     // const height = canvas.clientHeight;
//     // const needResize = canvas.width !== width || canvas.height !== height;
//     // if (needResize) {
//     //   renderer.setSize(width, height, false);
//     // }
//     // return needResize;
//   }

  private render() {
    // window.requestAnimationFrame(this.render.bind(this));
    // this.meshPiece.rotation.x += 0.002;
    // this.meshPiece.rotation.y += 0.002;
    // this.meshPiece.rotation.z += 0.002;

    // this.meshPiece.rotateOnAxis = ;
    // // this.cube01.rotation.x += 0.002;
    // // this.cube01.rotation.y += 0.002;
    // // this.cube01.rotation.z += 0.002;
    // // this.cube01_wireframe.rotation.x += 0.01;
    // // this.cube01_wireframe.rotation.y += 0.01;

    // // this.bar01.rotation.z-=0.01;
    // // this.bar02.rotation.z+=0.01;  

    // this.renderer.render( this.scene, this.camera );


    // if (this.resizeRendererToDisplaySize(this.renderer)) {
    //   const canvas = this.renderer.domElement;
    //   this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
    //   this.camera.updateProjectionMatrix();
    // }
    this.renderer.render(this.rtScene, this.rtCamera);
    this.renderer.setRenderTarget(this.renderTarget);
   this.renderer.setRenderTarget(null);
    // console.log(this.mouse.x + " --- " + this.mouse.y);
    this.renderer.render(this.scene, this.camera);
    // draw render target scene to render target





    ///******** */
    // rotate all the cubes in the render target scene
    // rtCubes.forEach((cube, ndx) => {
    //   const speed = 1 + ndx * .1;
    //   const rot = time * speed;
    //   cube.rotation.x = rot;
    //   cube.rotation.y = rot;
    // });



    // this.renderer2.setRenderTarget(null);

    // // rotate the cube in the scene
    // cube.rotation.x = time;
    // cube.rotation.y = time * 1.1;

    // render the scene to the canvas
    // this.renderer.render(scene, camera);

    //
    // this.meshPlane.quaternion.copy(this.camera.quaternion);
    // this.meshPlane.lookAt( this.camera.position );
    // this.meshPlane.position.set(this.meshPlane.position.x, this.meshPlane.position.y, this.camera.position.z-1);
    // this.plane.translateZ(100);

    // this.controls.update();

    // update the picking ray with the camera and mouse position
    // this.raycaster.setFromCamera( this.mouse, this.camera );

    // // // calculate objects intersecting the picking ray
    // const intersects = this.raycaster.intersectObjects( this.scene.children );
    // console.log(""+intersects.length);
    // for ( let i = 0; i < intersects.length; i ++ ) {

    //   // intersects[ i ].object.material.color.set( 0xff0000 );


    //   intersects[ i ].object.traverse(
        
    //     //this is a callback function
    //      ( child ) =>  {
    //     let self = this;

    //     if(child instanceof THREE.Mesh){
    //         // child.materials = [weiss, rot];
    //         child.geometry.computeVertexNormals();
    //         //const m1 = new THREE.MeshBasicMaterial({color: 0xFF0000});         // red
    //         const color = 0x00FF00;
    //         const material = new THREE.MeshPhongMaterial({
    //           color,
    //           opacity: 1,
    //           transparent: true,
    //         });
    //         var mesh = new THREE.Mesh(child.geometry, material);
    //         this.scene.add(mesh);
    //     }

    //     }
    //   );
    requestAnimationFrame(this.render.bind(this));

    // }

}
  ngOnChanges(){
    // console.log("I am being called"+this.item);
    if (this.item) this.loadPiece(this.item);
    requestAnimationFrame(this.render.bind(this));

  }

  // onResize() {
  // //  this.renderer.clear();   
  // //   this.renderer.render(this.scene, this.camera);
  // }
 
}
