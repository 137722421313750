import { Conditional } from '@angular/compiler';
import { Component, OnInit, Input } from '@angular/core';
import * as THREE from 'three';
import { Vector2, Vector3, WebGLRenderer, Scene, Color, Object3D, Mesh, Box3, MeshMatcapMaterial,WebGLRenderTarget,
  MeshPhongMaterial } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

@Component({
  selector: 'textureplane',
  templateUrl: './textureplane.component.html',
  styleUrls: ['./textureplane.component.css']
})
export class TextureplaneComponent implements OnInit {
  @Input() item!: string; // decorate the property with @Input()
  @Input() colorViewer!: string; // decorate the property with @Input()
  @Input() mainfile!: string; // decorate the property with @Input()
  rtScene : Scene;
  loader : GLTFLoader;

  constructor() { }

  ngOnInit(): void {
    const width = window.innerHeight/1;
    const height = window.innerHeight/1.5;

    const canvas1 = <HTMLCanvasElement>  document.querySelector('#c1');
    const renderer1 = new THREE.WebGLRenderer({ canvas: canvas1, alpha : true});
    renderer1.setClearColor( this.colorViewer, 1.0 );
    renderer1.setSize( width , height );


    const rtWidth = width;
    const rtHeight = height;
    const renderTarget = new THREE.WebGLRenderTarget(rtWidth, rtHeight, { format: THREE.RGBAFormat });
  
    const rtFov = 75;
    const rtAspect = rtWidth / rtHeight;
    const rtNear = 0.1;
    const rtFar = 10000;
    const rtCamera = new THREE.PerspectiveCamera(rtFov, rtAspect, rtNear, rtFar);
    rtCamera.position.z = 50;
  
    this.rtScene = new THREE.Scene();
    // this.rtScene.background = new THREE.Color('blue');
    rtCamera.lookAt(this.rtScene.position);
    this.rtScene.add( rtCamera );
    {
      const color = 0xFFFFFF;
      const intensity = 0.5;

      const rtambientlight = new THREE.AmbientLight(color, intensity);
      rtCamera.add( rtambientlight );


      // const light = new THREE.DirectionalLight(color, intensity);
      // light.position.set(-1, 2, 4);
      // this.rtScene.add(light);


      // const color = 0xFFFFFF;
      // const intensity = 0.5;
      // const ambientlight = new THREE.AmbientLight(color, intensity);
      // camera.add( ambientlight );

      const rtdirectionallight = new THREE.DirectionalLight(color, intensity);
      rtdirectionallight.target.position.set(0, 0, 0);
      const helper = new THREE.DirectionalLightHelper(rtdirectionallight);

      rtCamera.add( rtdirectionallight );
    }
  

    //REAL CAMERA
    const fov = 75;
    const aspect = width / height;  // the canvas default
    const near = 0.1;
    const far = 10000;
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.z = 100;
  
    const scene = new THREE.Scene();
    camera.lookAt(scene.position);
    scene.add( camera );
    {
      const color = 0xFFFFFF;
      const intensity = 0.5;
      const ambientlight = new THREE.AmbientLight(color, intensity);
      camera.add( ambientlight );

      const directionallight = new THREE.DirectionalLight(color, intensity);
      directionallight.target.position.set(0, 0, 0);
      const helper = new THREE.DirectionalLightHelper(directionallight);

      camera.add( directionallight );

    }
  
    //add the core part of the puzzle
    if (this.mainfile) {
      const loader = new GLTFLoader();
  
      console.log("load piece "+this.mainfile);
      loader.loadAsync(this.mainfile)
      
      // this.loader.loadAsync('/assets/puzzle1/puzzleall.glb')
      .then(data => 
        
        this.loaderF(data,scene,new THREE.Color(0xFFFFFF),1,false), 
        function reject() {
          console.log("there was an error loading")
        } 
      );
    }

    //add the plane on the viewport

    const material = new THREE.MeshPhongMaterial({
      map: renderTarget.texture,
    });
    // const cube = new THREE.Mesh(geometry, material);
    // scene.add(cube);
    // var geo1 = new THREE.PlaneGeometry(2, 2, 1 );
    var geo1 = new THREE.PlaneGeometry(1, 1 , 1 );
    // });
    const mymaterial = new THREE.MeshPhongMaterial({
      map: renderTarget.texture,
      transparent: true

    });   
    // console.log(this.mymaterial.map?.isTexture);
    var viewportPlane = new THREE.Mesh( geo1, mymaterial  );
    scene.add(viewportPlane);


    viewportPlane.lookAt( camera.position );
    // camera.updateMatrixWorld();
        viewportPlane.geometry.translate(
      0,
      0,
      // 0);
      camera.position.z-1);
    
    // // -this.camera.position.x,this.camera.lookAt().x-this.camera.position.x;
    // var vect = new Vector3();
    // // vect.project(this.camera)
    // // this.camera.position.x-this.bbox.getCenter();
    // // var a = ;

    // viewportPlane.geometry.translate(
    //   0,
    //   0,
    //   -1);


    //take care of controls
    const controls = new OrbitControls(camera, renderer1.domElement);
    controls.enableZoom = false;


    function resizeRendererToDisplaySize(renderer : any) {
      const canvas = renderer1.domElement;
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;
      const needResize = canvas.width !== width || canvas.height !== height;
      if (needResize) {
        renderer1.setSize(width, height, false);
      }
      return needResize;
    }
  
    const render = (time: any) => {
      time *= 0.001;
  
      if (resizeRendererToDisplaySize(renderer1)) {
        const canvas = renderer1.domElement;
        camera.aspect = canvas.clientWidth / canvas.clientHeight;
        camera.updateProjectionMatrix();
      }
  
      // rotate all the cubes in the render target scene
      // rtCubes.forEach((cube, ndx) => {
      //   const speed = 1 + ndx * .1;
      //   const rot = time * speed;
      //   cube.rotation.x = rot;
      //   cube.rotation.y = rot;
      // });
  
      // draw render target scene to render target
      // renderer1.clearTarget( renderTarget, true, true, true);

      renderer1.setRenderTarget(renderTarget);
      // renderer1.setClearColor( 'red', 1 );
      // renderer1.autoClearColor= true;
      renderer1.setClearColor( this.colorViewer, 0 );

      renderer1.render(this.rtScene, rtCamera);
      // renderer1.clearTarget( renderTarget, true, false, false );
      renderer1.setRenderTarget(null);
      // renderer1.setClearColor( 'black', 1.0 );
      renderer1.setClearColor( this.colorViewer, 1.0 );

  
      // render the scene to the canvas
      viewportPlane.lookAt( camera.position );
      renderer1.render(scene, camera);
  
      requestAnimationFrame(render);
    }
      requestAnimationFrame(render);
  
  }
  ngOnChanges(){
    if (this.item) {
      const loader = new GLTFLoader();
  
      console.log("load piece "+this.item);
      loader.loadAsync(this.item)
      
      // this.loader.loadAsync('/assets/puzzle1/puzzleall.glb')
      .then(data => this.loaderF(data,this.rtScene,new THREE.Color( 0xff0000 ), 0.5, false), 
        function reject() {
          console.log("there was an error loading")
        } 
      );
  
      
      }
      // const loaderF = (data: any) =>{
        
        
      //     console.log(data);
      //     const model = data.scene.children[0];
           
      //     model.traverse(
            
      //       //this is a callback function
      //        ( child: { geometry: THREE.BufferGeometry | undefined; } ) =>  {
    
      //       if(child instanceof THREE.Mesh){
      //           // child.materials = [weiss, rot];
      //           child.geometry.computeVertexNormals();
      //           //const m1 = new THREE.MeshBasicMaterial({color: 0xFF0000});         // red
      //           const color = 0xFF0000;
      //           const material = new THREE.MeshPhongMaterial({
      //             color,
      //             opacity: 1,
      //             transparent: false,
      //           });
      //           const meshPiece = new THREE.Mesh(child.geometry, material);
      //           // this.scene.add(this.meshPiece);
      //           this.rtScene.add(meshPiece);
      //       }
    
      //       }
      //     );
      
      //     // this.model.customDepthMaterial(material);
      //     // const cube = new THREE.Mesh(this.model., material);
    
      //     //this.scene.add(this.model);
    
      // }
  }

  loaderF(data: any, scene : Scene, thecolor: Color, theopacity : number, thetransparency : boolean){
        
        
    console.log(data);
    const model = data.scene.children[0];
     
    model.traverse(
      
      //this is a callback function
       ( child: { geometry: THREE.BufferGeometry | undefined; } ) =>  {

      if(child instanceof THREE.Mesh){
          // child.materials = [weiss, rot];
          child.geometry.computeVertexNormals();
          //const m1 = new THREE.MeshBasicMaterial({color: 0xFF0000});         // red
          const color = thecolor;
          const material = new THREE.MeshPhongMaterial({
            color,
            opacity: theopacity,
            transparent: thetransparency,
          });
          const meshPiece = new THREE.Mesh(child.geometry, material);
          // this.scene.add(this.meshPiece);
          scene.add(meshPiece);
      }

      }
    );
    }
}
