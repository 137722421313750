import * as THREE from 'three';
import { Scene,
    PerspectiveCamera, Color, Object3D, Vector3, ShaderMaterial, Vector, OrthographicCamera, BufferGeometry } from 'three';
    
import { GameAsset } from './game-asset';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

//this is the father class, it provides two methods: one to load a file (usually the full pot), and one to load the pieces
export class SceneBasic {
    
   
    // scene for the pot
    public scene : Scene;
    public camera : PerspectiveCamera;
    public posDistanceCamera : number;
    public widthScreen : number;
    public heightScreen : number;

    //this can be used by other classes to setup one asset
    public puzzleAsset : GameAsset;
    // public hiddenFullPot : GameAsset;
    // public innerCore : GameAsset;
    // shadermat : ShaderMaterial;

    readonly fov = 70;
    readonly near = 5;
    aspect : number;
    readonly far = 1500;

    public controls : OrbitControls;

    constructor(width : number, height : number){
        this.widthScreen = width;
        this.heightScreen = height;
        this.aspect = width / height;  // the canvas default
        // this.camera.aspect = this.aspect; 
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(this.fov, this.aspect, this.near, this.far);
        this.camera.fov = this.fov;
        this.camera.near = this.near;
        this.camera.far = this.far;
        this.scene.add( this.camera );
        
        //light
        const color = 0xFFFFFF;
        const intensity = 0.6;
        const ambientlight = new THREE.AmbientLight(color, intensity);
        // this.camera.add(ambientlight);
        const directionallight = new THREE.DirectionalLight(color, intensity);
        directionallight.target.position.set(0, 0, 0);//.normalize();
        // directionallight.position.set(10,10,10);
        // const helper = new THREE.DirectionalLightHelper(directionallight);
        this.scene.add(ambientlight);
        //add the light to the camera
        this.camera.add(directionallight);
        // this.camera.position.set(0,0,0);

        //add controls
        this.controls = new OrbitControls( this.camera,<HTMLCanvasElement>  document.querySelector('#c1'));
        this.controls.enabled = false;

        //initialise the main puzzle Asset associated with a basic scene
        this.puzzleAsset = new GameAsset();
        // this.hiddenFullPot = new GameAsset();

    }

    

    setupModel(file : string, name : string) : Promise<GameAsset>  {
      // this.puzzleAsset.uniforms.alpha = {value: this.puzzleAsset.transparency};  // default to max

      return new Promise((resolve,reject) =>{
        this.puzzleAsset.loadFile(file)
        .then((mesh) =>{
          // const material = new THREE.MeshPhongMaterial({
          //   color : new Color(0X333333),
          //   opacity: 0.4,
          //   wireframe : false,
          //   transparent: true,
          // });
          // console.log("I am now ready Main Pot: ",mesh);
          var meshPiece = new THREE.Mesh(mesh[0].geometry, this.puzzleAsset.material);
          meshPiece.position.set(mesh[0].position.x,mesh[0].position.y,mesh[0].position.z);
          // meshPiece.renderOrder =0;
          meshPiece.visible = true;
          meshPiece.name = name;
          this.scene.add(meshPiece);
          
          //compute the bounding box and the centre
          const bbox = new THREE.Box3().setFromObject(meshPiece);
          //compute the distance to the centre
          this.posDistanceCamera = this.puzzleAsset.getDistanceCamera(meshPiece, this.camera.fov);
          this.camera.position.x = 0;
          this.camera.position.y = 0;
          this.camera.position.z = this.posDistanceCamera;
    
          //add all of this info to the game asset
          this.puzzleAsset.bbox = bbox;
          bbox.getCenter(this.puzzleAsset.centerModel);
          this.puzzleAsset.distanceCamera = this.posDistanceCamera;
          this.puzzleAsset.centerModel = bbox.getCenter(new Vector3());
          
          this.camera.position.set(0,0,this.puzzleAsset.distanceCamera);
          this.camera.lookAt(this.scene.position);
          resolve(this.puzzleAsset);
        }
    
        ).catch(function(error){
          console.log("there was an error in the process");
        })  
           
      }); 
    }
    // setupPiece(file : string, name : string) {
     
    //   const material = new THREE.MeshPhongMaterial({
    //     color : new Color(0X111111),
    //     opacity: 1,
    //     wireframe : false,
    //     transparent: true,
    //   });
    //   var piceinScene = new GameAsset();
    //   piceinScene.loadFile(file)
    //   .then((result) =>{
    //     console.log(this.scene);
    //     //set the material
    //     piceinScene.uniforms.alpha.value = 1.0;
    //     var meshPiece = new THREE.Mesh(result[0].geometry, material);
    //     //set the name
    //     piceinScene.name = name;
    //     meshPiece.name = name;
    //     meshPiece.position.set(result[0].position.x, result[0].position.y, result[0].position.z);
    //     this.scene.add(meshPiece);
        
    //     // //compute its bounding box
    //     // this.mainPuzzleGameAsset.bbox = new THREE.Box3().setFromObject(meshPiece);
    //     // this.mainPuzzleGameAsset.bbox.getCenter(this.mainPuzzleGameAsset.centerModel);
    //     // this.mainPuzzleGameAsset.distanceCamera = this.distancetoCentre;
  
    //   }
  
    //   ).catch(function(error){
    //     console.log("there was an error in the process");
    //   })    
    // }
  //   setupInvisibleMainModel(file : string){
  //   this.hiddenFullPot.material  = new THREE.ShaderMaterial( {
  //     uniforms: this.hiddenFullPot.uniforms,
  //     vertexShader : GameAsset.vertexShader,
  //     fragmentShader : GameAsset.fragmentShader,   
  //     depthTest: true,
  //     lights : true,
  //     transparent: true,
  //     side: THREE.DoubleSide,
  //     blendDst : 1,

  //   } );
  //   this.hiddenFullPot.loadFile(file)
  //   .then((mesh) =>{
  //     // console.log("I am now ready loadFile Piece Pot:",mesh);
  //     var meshPiece = new THREE.Mesh(mesh[0].geometry, this.hiddenFullPot.material);
  //     meshPiece.position.set(mesh[0].position.x,mesh[0].position.y,mesh[0].position.z);
  //     meshPiece.visible = false;
  //     this.scene.add(meshPiece);
      
  //     //compute the bounding box and the centre
  //     this.hiddenFullPot.bbox  = new THREE.Box3().setFromObject(meshPiece);
  //     // this.centreMainPuzzle = new Vector3();
  //     this.hiddenFullPot.centerModel = this.hiddenFullPot.bbox.getCenter(new Vector3());

  //     //compute the distance to the centre
  //     this.hiddenFullPot.distanceCamera = this.hiddenFullPot.getDistanceCamera(meshPiece, this.camera.fov);
  //     // this.camera.position.x = 0;
  //     // this.camera.position.y = 0;
  //     // this.camera.position.z = this.hiddenFullPot.distanceCamera;


  //   });
  // }

    getMainPuzzleAsset() : GameAsset{
      return this.puzzleAsset;

    }
}