import { Component, OnInit, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { countUpTimerConfigModel, CountupTimerService, timerTexts } from 'ngx-timer';    
import { HIGH_CONTRAST_MODE_ACTIVE_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';

import { AngularFirestore, } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import { toBase64String } from '@angular/compiler/src/output/source_map';

declare global {
  export interface PieceAttempt {
      stepNum: number;
      puzzlePieceNumSelected: string;
      startTime: string;
      endTime: string;
      completionTimePiece: number;
      usedClues: boolean;
      transparencyLevel : number;
      rotationCameraAzimuth : number;
      rotationCameraPolar : number;
      succesfulAttempt: boolean;
    }
  export interface Result {
      userID: string;
      startTime: string;
      endTime: string;
      completionTime: string;
      usedClues: boolean;
      OSused : string;
      browser: string;
    }
}


enum PieceState {
  AWAITING,
  PROCESSING,
  DONE,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})

export class AppComponent implements OnInit {
  title = 'kundekariAngular';

  filepath = '/assets/puzzle4/';
  fileallpuzzle = this.filepath+'puzzleall.glb';
  // fileallpuzzle = this.filepath+'kundekariall.glb';
  files = [
    { image: "/assets/puzzle4/allthum/puzzle4_thumb.jpg", num: 4, file: this.filepath+'puzzle4.glb', texture: this.filepath+'puzzle4_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle5_thumb.jpg", num: 5, file: this.filepath+'puzzle5.glb', texture: this.filepath+'puzzle5_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle7_thumb.jpg", num: 7, file: this.filepath+'puzzle7.glb', texture: this.filepath+'puzzle7_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle0_thumb.jpg", num: 0, file: this.filepath+'puzzle0.glb', texture: this.filepath+'puzzle0_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle1_thumb.jpg", num: 1, file: this.filepath+'puzzle1.glb', texture: this.filepath+'puzzle1_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle2_thumb.jpg", num: 2, file: this.filepath+'puzzle2.glb', texture: this.filepath+'puzzle2_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle3_thumb.jpg", num: 3, file: this.filepath+'puzzle3.glb', texture: this.filepath+'puzzle3_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle6_thumb.jpg", num: 6, file: this.filepath+'puzzle6.glb', texture: this.filepath+'puzzle6_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle8_thumb.jpg", num: 8, file: this.filepath+'puzzle8.glb', texture: this.filepath+'puzzle8_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle9_thumb.jpg", num: 9, file: this.filepath+'puzzle9.glb', texture: this.filepath+'puzzle9_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle10_thumb.jpg", num: 10, file: this.filepath+'puzzle10.glb', texture: this.filepath+'puzzle10_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle11_thumb.jpg", num: 11, file: this.filepath+'puzzle11.glb', texture: this.filepath+'puzzle11_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle12_thumb.jpg", num: 12, file: this.filepath+'puzzle12.glb', texture: this.filepath+'puzzle12_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle13_thumb.jpg", num: 13, file: this.filepath+'puzzle13.glb', texture: this.filepath+'puzzle13_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle14_thumb.jpg", num: 14, file: this.filepath+'puzzle14.glb', texture: this.filepath+'puzzle14_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
    { image: "/assets/puzzle4/allthum/puzzle15_thumb.jpg", num: 15, file: this.filepath+'puzzle15.glb', texture: this.filepath+'puzzle15_diffuse.jpg', disabled: false, state: PieceState.AWAITING, clue: false},
  ];

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(): void {

    
    // this.thisUser.OSused = "test one";
    // this.firestore.collection(this.collection).doc(this.thisUser.userID).set(this.thisUser);
 
  }
  
  clues = [];//[2,4];
  //files =[];
  itemToAdd = '';
  itemTexToAdd = '';
  itemIDToAdd = '';

  itemState = 0;
  jsonfile = "/assets/times.json"

  // done = [
  //   '/assets/Parrot.glb'
  // ];
  buttonDisabled = false;
  breakpoint = 1;
  testConfig : countUpTimerConfigModel;

  public thisUser : Result;
  id : string;

  firestore : AngularFirestore;
  firebaseAuth: AngularFireAuth; // Inject Firebase auth service

  private collection = 'users'; // make available to class

  // countupTimerService: CountupTimerService;
  // play() {}
  constructor(private countupTimerService : CountupTimerService, firestore: AngularFirestore, firebaseAuth: AngularFireAuth){
    // this.countupTimerService = new CountupTimerService();
    //countUpTimerConfigModel
    this.testConfig = new countUpTimerConfigModel();
        
    //custom class
    this.testConfig.timerClass  = 'test_Timer_class';
    this.testConfig.timerTexts = new timerTexts();
    // this.testConfig.ti
    
    this.testConfig.timerTexts.hourText = " :"; //default - hh
    this.testConfig.timerTexts.minuteText = " :"; //default - mm
    this.testConfig.timerTexts.secondsText = " "; //default - ss
    // console.log(window.innerWidth);
    // this.countupTimerService.isTimerStart = true;
    // this.countupTimerService.setTimervalue(0);
    // this.countupTimerService.stopTimer();
    //this.countupTimerService.startTimer();
    // this.countupTimerService.stopTimer();
    //set the clues
    this.files.forEach((element)=>{
      for (var i=0;i<this.clues.length;i++){
        if (this.clues[i]===element.num) {
            element.disabled = true;
            element.clue = true;
            element.state = PieceState.DONE;
        }
      } 
      //console.log(element);//this.files.push(element);

    }
    );
    //console.log(this.files);

    this.firestore    = firestore;
    this.firebaseAuth = firebaseAuth;

    const getID = () => {
      let id : string;
      let isUnique = false;

      do {
        id = firestore.createId();

        firestore.collection(this.collection).doc(id).get().toPromise().then((docSnapshot) => {
          isUnique = !docSnapshot.exists;
        });
      } while (isUnique);

      return id;
    };

    const getOS = () => {
      const map = [
        { key: "Linux", value: "Linux"   }, // Linux-based
        { key: "Mac",   value: "macOS"   }, // MacOS/IOS
        { key: "Win",   value: "Windows" }, // Windows
        { key: "X11",   value: "Unix"    }  // Unix-based
      ];

      for (const pair of map)
        if (navigator.appVersion.indexOf(pair.key) != -1)
          return pair.value;

      return "Other";
    };

    const getBrowser = () => {
      const map = [ // This map is order-dependent and is not alphabetical
        { keys: [ "Opera", "OPR" ], value: "Opera"             }, // Opera
        { keys: [ "Chrome" ],       value: "Chrome"            }, // Google Chrome
        { keys: [ "Edge" ],         value: "Edge"              }, // Microsoft Edge
        { keys: [ "Firefox" ],      value: "Firefox"           }, // Mozilla Firefox
        { keys: [ "MSIE" ],         value: "Internet Explorer" }, // Microsoft Internet Explorer
        { keys: [ "Safari" ],       value: "Safari"            }, // Apple Safari
      ];

      for (const pair of map)
        for (const key of pair.keys)
          if (navigator.userAgent.indexOf(key) != -1)
            return pair.value;

      return "Other";
    };
    

    const getDomain = () => {
      

      let hostname : string | null;
      hostname = null;

      let origins_i : DOMStringList;
      var length_i = 0;
      try {
        origins_i = window.location.ancestorOrigins;
        length_i = location.ancestorOrigins.length;

        hostname = window.location.hostname;

        if ( origins_i != null && length_i != 0) {
        hostname = window.location.ancestorOrigins.item(0);

        if (hostname != null) {
          let index : number;

          if ((index = hostname.indexOf("//")) != -1) {
            hostname = hostname.substring(index + 2);
          }
        }
        }
      }catch{
        console.log("cannot read ancestors");
      }

    
      return hostname != null ? hostname.replace("www.", "") : "";
    }

    const date = new Date ();

    this.id  = getID();
    this.thisUser = {
      startTime: "DNS",
      endTime: "DNF",
      completionTime: "DNF",
      userID: this.id,
      usedClues: false,
      OSused: getOS(),
      browser: getBrowser(),
      domain: getDomain()
    } as Result;

    this.firestore.collection(this.collection).doc(this.thisUser.userID).set(this.thisUser);
  }

  ngOnInit(): void {
    this.breakpoint = (window.innerWidth <= 600) ? 1 : 
                      (window.innerWidth > 600 && window.innerWidth <= 1200) ? 4 : 
                      (window.innerWidth > 1200) ? 6 : 4;

  }


  onResize(event : any) {
    console.log(window.innerWidth);
    this.breakpoint = (window.innerWidth <= 500) ? 1 : 
    (window.innerWidth > 500 && window.innerWidth <= 1000) ? 2 : 
    (window.innerWidth > 1000 && window.innerWidth <= 1500) ? 3 : 
    (window.innerWidth > 1500 && window.innerWidth <= 2000) ? 4 : 
    (window.innerWidth > 2000) ? 5 : 3;
  }

  started(event: any) {
    console.log("I am moving");
  
  }
  dropped(event: any) {
    console.log("****I am dropped");
  
  }

  
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
   }


  
  modifyJson(id : string, disabled: boolean, state: PieceState) {
    return new Promise((resolve,reject) =>{
      this.files.forEach((el, index) => {
        if (el.num.toString()===id) {
          // console.log("!!!!found it", el);
          this.files[index].disabled = disabled;
          this.files[index].state = state;
          resolve("OK");
          // resolve(map1);
        }
      });
    // resolve("OK");


    });


    // return Promise.all(Object.keys(en).map(async (item) => {
    //   if (typeof en[item] === 'object') {
    //     await this.modifyJson(en[item]);
    //   } else {
    //     en[item] = await this.asynctranslateText();
    //   }
    // }));
  }
  
  myOnClick($event: MouseEvent){
    if (!this.countupTimerService.isTimerStart) {
      this.countupTimerService.startTimer();
      this.thisUser.startTime = this.getAbsoluteTime();
      this.firestore.collection(this.collection).doc(this.thisUser.userID).set(this.thisUser);
    }
    var id = ($event.currentTarget as HTMLElement).id;
    this.files.forEach((el, index) => {
      if (el.num.toString()===id) {
        console.log(this.files);
        console.log(el.num.toString());
        switch(el.state) {
          //if awaiting, go ahead and load the piece 
          case PieceState.AWAITING:
          //but not unless no other is in the processing stage 
          var thenumber = this.isProcessing();

          // console.log("---->>>>",thenumber);
            if (thenumber==-1){
              // clean any messages
              // console.log("---->",this.files[index]);
              const newDiv = <HTMLDivElement> document.getElementById('errorMessages');
              newDiv.innerHTML = "";
              // set the new flags for buttons
              this.modifyJson(el.num.toString(),true,PieceState.PROCESSING).then((result) =>{
                console.log("resolved",this.files);
                console.log("resolved",result);
                //data to be sent
                this.itemToAdd = el.file;
                this.itemTexToAdd = el.texture;
                this.itemIDToAdd = id;
                this.itemState = PieceState.PROCESSING;

                // this.files.push(this.files.splice(index, 1)[0]);

                }
                ).catch(function(error){
                  console.log("there was an error in the process");
                });
              // el.disabled = true;
              // el.state = PieceState.PROCESSING;
              // console.log("---->",this.files[index]);



            }else{
              //if there is another piece being processed
              //disable the other one and bring it back to waiting - do it with promise
              // this.files[thenumber].disabled = false;
              // this.files[thenumber].state = PieceState.AWAITING;
              console.log("---->>>>>>>>>>",thenumber);

              this.modifyJson(thenumber.toString(),false,PieceState.AWAITING).then((result) =>{
                console.log("---->>>>>>>>>>",thenumber);

            
                // //now enable the one that was pressed
                // el.disabled = true;
                // el.state = PieceState.PROCESSING;
                this.modifyJson(el.num.toString(),true,PieceState.PROCESSING).then((result) =>{
                  //data to be sent
                  this.itemToAdd = el.file;
                  this.itemTexToAdd = el.texture;
                  this.itemIDToAdd = id;
                  this.itemState = PieceState.PROCESSING;

                  //first put back the last one at the begining
                  // console.log("********",this.files.splice(this.files.length-1, 1)[0]);
                  // console.log(this.files);
                  // this.files.push(this.files.splice(index, 1)[0]);
                  // this.files.unshift(this.files.splice(this.files.length-2, 1)[0]);

                  // const newDiv = <HTMLDivElement> document.getElementById('errorMessages');
                  // newDiv.innerHTML = thenumber+"Before trying a new piece, unclick the one you have loaded or fit the current piece.";      
                }
                
                ).catch(function(error){
                  console.log("there was an error in the process");
                });

              }
              ).catch(function(error){
                console.log("there was an error in the process");
              });
              
             
            }
          break;
          case PieceState.PROCESSING:
             //clean any messages
            //  console.log("undoing it ")
             const newDiv1 = <HTMLDivElement> document.getElementById('errorMessages');
             newDiv1.innerHTML = "";
             //set the new flags for buttons
             el.disabled = false;
             el.state = PieceState.AWAITING;
             this.modifyJson(el.num.toString(),false,PieceState.AWAITING).then((result) =>{
                //data to be sent
                this.itemToAdd = el.file;
                this.itemTexToAdd = el.texture;
                this.itemIDToAdd = id;
                this.itemState = PieceState.AWAITING;

                // this.files.push(this.files.splice(index, 1)[0]);

            }
            ).catch(function(error){
              console.log("there was an error in the process");
            });
          break;
          case PieceState.DONE:
             //clean any messages
             const newDiv2 = <HTMLDivElement> document.getElementById('errorMessages');
             newDiv2.innerHTML = "This piece is already fitted.";            
          break;

       
        }
        // console.log(el.num.toString());
        //check whether the button disabled flag is on for this piece
        // switch(el.disabled) {
        //   //if disabled is true, means we can proceed to load the piece
        //   case true:
        //     //remove any error messages
        //     const newDiv = <HTMLDivElement> document.getElementById('errorMessages');
        //     newDiv.innerHTML = "";

        //     el.disabled = false;
        //     //the piece is awaiting to be fixed
        //     // el.state = PieceState.AWAITING;

        //     //assign all values
        //     this.itemToAdd = el.file;
        //     this.itemIDToAdd = id;
        //     this.itemState  = Math.random();
        //     break;
        //   //if disabled is false, means we can proceed to load the piece           
        //   case false:
        //     //first check if there are no items processing in the scene
        //     if (!this.isProcessing()){
        //       // console.log("case false");
        //       el.disabled = true;
        //       // indextochangeDisabled = index;
        //       //change the item to be processing, as the user will try to fit it
        //       el.state = PieceState.PROCESSING;
            
        //       // this.todo.push(this.todo.splice(index, 1)[0]);
        //       // this.itemToAdd = el.file;
        //       // this.itemIDToAdd = id;
        //       // this.itemState = Math.random();

        //       // console.log("->>>this.itemToAdd  "+this.itemToAdd + " this.itemIDToAdd: "+this.itemIDToAdd);
        //     }else{
        //       const newDiv = <HTMLDivElement> document.getElementById('errorMessages');
        //       newDiv.innerHTML = "Before trying a new piece, unclick the one you have loaded or fit the current piece.";

        //     }
        //     break;

        // }

      }

    });
    // console.log("this+ " +this.itemToAdd + " "+ this.itemIDToAdd);
    // this.cd.detectChanges();

    //send this to the end of the list so it appears at the end
    // if(indextochangeDisabled!=-1) this.todo.push(this.todo.splice(indextochangeDisabled, 1)[0]);
    //send to the front
    // if(indextochangeEnabled!=-1) this.todo.unshift(this.todo.splice(indextochangeEnabled, 1)[0]);

   }
   isProcessing() : number {
    var thenumberinButton = -1;
    // console.log(thenumberinButton);

    let isP: boolean = false;
    this.files.forEach((el, index) => 
    {
      // console.log("el.state",el.state);
      if (el.state===PieceState.PROCESSING){
        // console.log("el.state",el.state);

        isP = true;  
        thenumberinButton = el.num;
        // console.log("Oa, ",thenumberinButton);
      }

    }
    
    );
    // console.log(thenumberinButton);
    return thenumberinButton;
  }
   onDone($event: number) {
    console.log("----->!!!",$event);
    // this.files[ $event].state = PieceState.DONE;

    this.modifyJson($event.toString(),true,PieceState.DONE).then((result) =>{


      console.log("check finish: "+this.checkFinished());
      if (this.checkFinished()){
        this.countupTimerService.pauseTimer();
        let myDialog:any = <any>document.getElementById("myDialog");

        this.thisUser.endTime = this.getAbsoluteTime ();
        this.thisUser.completionTime = this.getRelativeTime ();

        this.firestore.collection(this.collection).doc(this.thisUser.userID).set(this.thisUser);

        myDialog.innerHTML = "You completed the puzzle in "+//+this.countupTimerService.timerValue.hours+" : hr "+
        this.countupTimerService.timerValue.mins+
        " : "+this.countupTimerService.timerValue.seconds+" sec ";
        
        myDialog.showModal();
        setTimeout(function () {
          myDialog.close();
      }, 5000);      

      }
    }
    ).catch(function(error){
      console.log("there was an error in the process");
    });
    //  console.log("YEIII I heard  back "+this.todo[this.message].state);
  }
   //put it back on awaiting
   onReturn($event: number) {
    this.files[$event].state = PieceState.AWAITING;
   //  console.log("YEIII I heard  back "+this.todo[this.message].state);
  }
  
  checkFinished() : boolean{
    let count = 0;
    this.files.forEach((el, index) => {
      if (el.state==PieceState.DONE) 
        count++;
      
    }
    
    );
    // console.log(count);
    // console.log(this.files.length);

    if (count == this.files.length) return true;
    // if (count == 1) return true;
    else return false;
  }
  ngOnDestroy(){

  }
  getCustomCss(number : number, disabled : boolean){
    let thec = 'btn-'+number;
    // console.log(thec)
    if (disabled)
      return 'btn-disabled';
    else 
      return thec;
  
  }

  getAbsoluteTime() : string {
    return (new Date).toString();
  }

  getRelativeTime() : string {
    return this.countupTimerService.timerValue.hours + ":" +
           this.countupTimerService.timerValue.mins  + ":" +
           this.countupTimerService.timerValue.seconds;
  }

  onUsedClues() {
    this.thisUser.usedClues = true;
    this.firestore.collection(this.collection).doc(this.thisUser.userID).set(this.thisUser);
  }
 
}
