import { Conditional, rendererTypeName } from '@angular/compiler';
import { Component, OnInit, Input, Output, 
  ChangeDetectionStrategy, OnChanges, EventEmitter, 
  Injectable, 
  OnDestroy,
  HostListener} from '@angular/core';
import { Observable, combineLatest  } from 'rxjs';
import { MatSliderChange } from '@angular/material/slider';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as THREE from 'three';
import { Vector2, Vector3, Matrix4, WebGLRenderer, Scene, Color, 
  PerspectiveCamera, OrthographicCamera, Object3D, Mesh, Box3, MeshMatcapMaterial,WebGLRenderTarget,
  MeshPhongMaterial, MeshBasicMaterial, Camera, Quaternion, UniformsLib, ShaderMaterial, DepthTexture, PositionalAudio, LessDepth,
sRGBEncoding,
MathUtils} from 'three';
import { SceneBasic } from './scene-basic';
import { ScenePiece } from './scene-piece';
import { SceneAllPieces } from './scene-allpieces';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { AngularFirestore, } from '@angular/fire/firestore';


@Component({
  selector: 'view-gl',
  templateUrl: './view-gl.component.html',
  styleUrls: ['./view-gl.component.css'],
  // changeDetection : 
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers : [
   ]

})
export class ViewGLComponent implements OnInit, OnChanges {
  @Input() item!: string; // decorate the property with @Input()
  @Input() texture!: string; // decorate the property with @Input()
  @Input() itemID!: string; // decorate the property with @Input()
  public previousItemID = "";
  @Input() numPieces!: number; // decorate the property with @Input()
  @Input() itemState!: number; // decorate the property with @Input()
  @Input() colorViewer!: string; // decorate the property with @Input()
  @Input() mainfile!: string; // decorate the property with @Input()
  @Input() filepath!: string; // decorate the property with @Input()
  @Input() clues!: number[]; // decorate the property with @Input()
  @Input() userID: string; // decorate the property with @Input()

  // @Output() doneItem = new EventEmitter<string>(); //to send a message when an ID has been fixed correctly
  @Output() doneItem: EventEmitter<any> = new EventEmitter();
  @Output() returnItem: EventEmitter<any> = new EventEmitter();
  @Output() usedCluesItem: EventEmitter<any> = new EventEmitter();
  @Injectable() sceneFullPot: SceneBasic;
  @Injectable() scenePiecePot: ScenePiece;
  @Injectable() sceneAllPiecesPot: SceneAllPieces;
  
  //information for log
  public piecesAttempt : PieceAttempt[];
  public thispiecesAttempt : PieceAttempt;
  public step : number = 0;
  public start : number;
  public total_angle_azimuth : number = 0;
  public total_angle_polar : number = 0;

  // usersCollection : Observable<any[]>;

  private renderer : THREE.WebGLRenderer;
  public itemProcessingisDone : boolean;
  public cameraMoved : boolean;

  public renderTargets : WebGLRenderTarget[];
  public rtScene : Scene;
  public rtCamera : OrthographicCamera;
  public rtMaterial : MeshBasicMaterial;
  
  static readonly  width = window.innerHeight/2;
  static readonly  height = window.innerHeight/2;
  
  public readonly maxTransparency = 10;
  public showPieceHelp =  false;
  public pieceTransparency =  8;

  firestore : AngularFirestore;
  private readonly collection = 'users'; // make available to class
  private readonly attemptcollection = 'PiecesAttempt';
  constructor(firestore: AngularFirestore) {
    this.step = 0;
    this.itemProcessingisDone = false;
    this.firestore    = firestore;
    this.thispiecesAttempt = {} as PieceAttempt;


  }

  // static readonly  rtVertexShader : string = /* glsl */`
  // // varying vec2 vUv;

  // //   void main() {
  // //     vUv = uv;
  // //     gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  // //   }
  // //vertexShader: 
  
 
  //   precision highp float;
  //   precision highp int;
  //   varying vec2 vUv;
  //   //varying float v_textureIndex;
 
  //   void main() {
  //     //v_textureIndex = step(0.5, uv.x) + step(0.5, uv.y) * 2.0;
  //     vUv = uv;
  //     gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  //   }
 
  // `;

  // static readonly rtFragmentShader: string = /* glsl */`

  //   precision mediump float;

  //   varying vec2 vUv;
  //   uniform sampler2D tDiffuseScene;
  //   uniform sampler2D tDiffusePiece;
  //   uniform sampler2D tDepthPiece;
  //   uniform sampler2D tDiffuseClue;
  //   uniform sampler2D tDepthClue;

  //   //varying float v_textureIndex;



  //   void main() {
  //     vec4 piece = texture2D( tDiffusePiece, vUv );
  //     vec4 color = texture2D( tDiffuseClue, vUv );
  //     vec3  test   = step(1.0/512.0, piece.rgb);
  //     float a      = max(max(test.r, test.g), test.b);
  //     vec3  mixCol = mix(color.rgb,piece.rgb,  a);

  //      gl_FragColor = vec4(mixCol.rgb, color.a);
  //     // //gl_FragColor = vec4(1.0,0,0,1);
  //     // gl_FragColor = vec4(color.rgb,0);


  //   }
  //   `;

  ngOnInit() : void{

    this.renderTargets = new Array<THREE.WebGLRenderTarget>(3);
    const canvas1 = <HTMLCanvasElement>  document.querySelector('#c1');
    //set the renderer
    this.renderer = new THREE.WebGLRenderer({ 
      canvas: canvas1, 
      alpha : true, 
      // antialias: true, 
      depth : true,
  
    });

     // this.renderer.physicallyCorrectLights = true;
    // this.renderer.outputEncoding = sRGBEncoding;
    if ( this.renderer.capabilities.isWebGL2 === false && this.renderer.extensions.has( 'WEBGL_depth_texture' ) === false ) {
      document.getElementById( 'support' )!.innerHTML  = "WebGL is not supported by your browser. Try using Chrome or Mozilla Firefox.";
      return;

    }
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setClearColor( this.colorViewer, 1.0 );
    this.renderer.setSize( ViewGLComponent.width , ViewGLComponent.height );
    //this is the order in which things get set
    //first the full object
    this.sceneFullPot = new SceneBasic(ViewGLComponent.width , ViewGLComponent.height );
    //second the clues - where transparency is used
    this.scenePiecePot = new ScenePiece(ViewGLComponent.width , ViewGLComponent.height );
    //then all the individual pieces
    this.sceneAllPiecesPot = new SceneAllPieces(ViewGLComponent.width, ViewGLComponent.height );
    //to this point - nothing changes the camera distance
    this.sceneAllPiecesPot.filepath = this.filepath;
  
    //1. set the main puzzle scene
    this.setupMainModel()
    .then((cameraPosition) =>{
        this.sceneFullPot.scene.visible = true;
        this.scenePiecePot.camera.position.set(0,0,cameraPosition.z);
        console.log("TO CHANGE AGAIN HERE---->",cameraPosition.z);
        this.sceneAllPiecesPot.camera.position.set(0,0,-900);//cameraPosition.z);
        this.sceneAllPiecesPot.setupModelCores(this.numPieces,this.clues).then((result)=>{
          if (result) {
            // console.log("loaded");

            //this.sceneClues.setupModelLines();
          }
        });

    }
    ).catch(function(error){
    console.log("there was an error in the process");
    });   
    
    

    // this.sceneClues.innerCore.uniforms.alpha.value =  0.25;
    // this.sceneClues.innerCore.uniforms.diffuseColor.value = new Color(0XFFFFCC) ;
    // this.sceneClues.setupModelLines();
    
    //2. take care of controls
    const controls = new OrbitControls(this.sceneFullPot.camera, this.renderer.domElement);
    controls.enableZoom = false;
    controls.enablePan = false;
    this.cameraMoved = false;
    controls.addEventListener('change', () =>{
        this.cameraMoved = true;
        this.total_angle_azimuth = this.total_angle_azimuth + MathUtils.radToDeg(controls.getAzimuthalAngle());
        this.total_angle_polar = this.total_angle_polar + MathUtils.radToDeg(controls.getPolarAngle());
        // console.log("------>"+MathUtils.radToDeg(controls.getAzimuthalAngle())
        // +" "+MathUtils.radToDeg(controls.getPolarAngle()));
        // this.sceneClues.camera.position.set(0,0,0)
        // console.log(controls.target);
    });
    this.sceneAllPiecesPot.controls = controls;

    //3. create 3 render targets and the scene to display their outcome
    this.createRenderTargets();
    this.createRenderTargetScene();

    //3. handle resize 
    const resizeRendererToDisplaySize = (renderer : any) => {
      const canvas = this.renderer.domElement;
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;
      const needResize = canvas.width !== width || canvas.height !== height;
      if (needResize) {
        this.renderer.setSize(width, height, false);
      }
      return needResize;
    }

    //4. render function, which also takes care of producing the render target for the texture
    const render = (time: any) => {
      time *= 0.001;
      if (resizeRendererToDisplaySize(this.renderer)) {
        const canvas = this.renderer.domElement;
        this.sceneFullPot.camera.aspect = canvas.clientWidth / canvas.clientHeight;
        this.sceneFullPot.camera.updateProjectionMatrix();
      } 
      
      //****render 1 step ****the puzzle piece as a texture 
      this.renderer.setRenderTarget(this.renderTargets[0]);
      //first we render  with the backgroudn colour
      //this.renderer.setClearColor( this.colorViewer, 1 );
      this.renderer.setClearColor(0x00);
      this.renderer.render(this.sceneFullPot.scene,this.sceneFullPot.camera);
      
      // //****render 1 step ****the full scene as a texture 
      this.renderer.setRenderTarget(this.renderTargets[1]);
      this.renderer.setClearColor( this.colorViewer, 0 );
      this.renderer.render(this.scenePiecePot.scene, this.scenePiecePot.camera);

      this.renderer.setRenderTarget(this.renderTargets[2]);
      this.renderer.setClearColor( this.colorViewer, 1 );
      this.renderer.render(this.sceneAllPiecesPot.scene, this.sceneAllPiecesPot.camera);
      this.renderer.setRenderTarget(null);

      // this.rtMaterial.uniforms.tDiffuseScene.value = this.renderTargets[0].texture;
      // this.rtMaterial.uniforms.tDepthScene.value = this.renderTargets[0].texture;
      // this.rtMaterial.uniforms.tDiffusePiece.value = this.renderTargets[1].texture;
      // this.rtMaterial.uniforms.tDepthPiece.value = this.renderTargets[1].texture;
      // this.rtMaterial.uniforms.tDiffuseClue.value = this.renderTargets[2].texture;
      // this.rtMaterial.uniforms.tDepthClue.value = this.renderTargets[2].texture;
      this.renderer.setClearColor( this.colorViewer, 1.0 );
      this.renderer.render(this.rtScene,this.rtCamera);
      // this.renderer.render(this.sceneClues.scene,this.sceneClues.camera);

      // this.renderer.render(this.sceneFullPot.scene,this.sceneFullPot.camera);
      // this.renderer.render(this.scenePiecePot.scene, this.scenePiecePot.camera);
      // this.renderer.render(scenePiece, sceneCamera);
      if ((this.cameraMoved)&&(!this.itemProcessingisDone)){
        // let dis = this.camera.position.manhattanDistanceTo(this.rtCamera.position);
        // console.log(this.camera.position);
          if (this.sceneFullPot.camera.position.manhattanDistanceTo(this.scenePiecePot.camera.position)<=3) {            
            this.pieceMatches();
          }
        } 
      requestAnimationFrame(render);
      
    }
    requestAnimationFrame(render);
      
    
      
  }

  createRenderTargets(){
    for (var i=0; i<this.renderTargets.length; i++){
      this.renderTargets[i] = new THREE.WebGLRenderTarget(ViewGLComponent.width, ViewGLComponent.height); 
      this.renderTargets[i].texture.format = THREE.RGBAFormat;
      this.renderTargets[i].texture.minFilter = THREE.NearestFilter;
      this.renderTargets[i].texture.magFilter = THREE.NearestFilter;
      this.renderTargets[i].texture.wrapS = THREE.ClampToEdgeWrapping;
      this.renderTargets[i].texture.wrapT = THREE.ClampToEdgeWrapping;
      // this.renderTargets[i].texture.type = THREE.FloatType; 
      this.renderTargets[i].depthBuffer = true;
      this.renderTargets[i].depthTexture = new THREE.DepthTexture(ViewGLComponent.width, ViewGLComponent.height);
      this.renderTargets[i].depthTexture.format = THREE.DepthFormat;
      this.renderTargets[i].depthTexture.type = THREE.UnsignedShortType;  

      
   }

  }
  createRenderTargetScene(){
    // this.rtMaterial = new THREE.ShaderMaterial( {
    //   uniforms: {
    //     cameraNear: { value: this.sceneFullPot.camera.near },
    //     cameraFar: { value: this.sceneFullPot.camera.far },
    //     tDiffusePiece: { value: null },
    //     tDepthPiece: { value: null },
    //     tDiffuseScene: { value: null },
    //     tDepthScene: { value: null },
    //     tDiffuseClue: { value: null },
    //     tDepthClue: { value: null },
    //   },
    //   vertexShader : ViewGLComponent.rtVertexShader,
    //   fragmentShader : ViewGLComponent.rtFragmentShader,
    //   side: THREE.DoubleSide,
    //   transparent : true,
    //   premultipliedAlpha : true,
    //   depthTest : true,
    //   blending : THREE.CustomBlending,
    //   blendSrc : THREE.DstAlphaFactor,
    //   blendDst : THREE.OneMinusSrcAlphaFactor,
    //   blendEquation : THREE.AddEquation,

    // });   

  
    this.rtScene = new Scene();
    this.rtCamera = new THREE.OrthographicCamera( - 1, 1, 1, - 1, 0, 1 );
    
    const materialbg = new THREE.MeshBasicMaterial( { 
      map: this.renderTargets[2].texture 
    } );
    const backPlane = new THREE.PlaneGeometry(2, 2 );
    var backPlaneMesh = new THREE.Mesh( backPlane, materialbg);
    this.rtScene.add(backPlaneMesh);
    
    const materialfg = new THREE.MeshBasicMaterial( { 
      map: this.renderTargets[1].texture,
      transparent: true,

    } );
    const fronttPlane = new THREE.PlaneGeometry(2, 2 );
    var frontPlaneMesh = new THREE.Mesh( fronttPlane, materialfg);
    this.rtScene.add(frontPlaneMesh);

    // postPlaneMesh.position.set( 0, 0, - 1 );


    //lights
    const color = 0xFFFFFF;
    const intensity = 0.5;
    const ambientlight = new THREE.AmbientLight(color, intensity);
    this.rtCamera.add(ambientlight);

  }
  ngOnChanges(){
    if(this.itemID){
      // console.log( "children: " + this.scenePiecePot.scene.children.length);
      // console.log( this.scenePiecePot.scene);
      

       var objectinScene = this.scenePiecePot.scene.getObjectByName(this.itemID.toString());

      if ((!objectinScene)&&(this.scenePiecePot.scene.children.length>=3)){//the scenePiecePot should have 3 elements: camera, light and one mesh. 
        //If it already has this, then replace the mesh.
        // console.log("I need to replace something"+this.previousItemID);
        var prevousObjectinScene = this.scenePiecePot.scene.getObjectByName(this.previousItemID.toString());
        if(prevousObjectinScene){
          // console.log("there is something else"+this.thispiecesAttempt.stepNum);
          //send the previosu piece for firestore
          this.thispiecesAttempt.succesfulAttempt = false;
          this.logAnglesCamera();
          this.thispiecesAttempt.completionTimePiece = 9999;
          this.thispiecesAttempt.transparencyLevel = this.pieceTransparency;
          this.firestore.collection(this.collection).doc(this.userID).collection(this.attemptcollection).doc(this.userID+'_'+this.thispiecesAttempt.stepNum).set(this.thispiecesAttempt)

          this.scenePiecePot.scene.remove(prevousObjectinScene);
          let objectinSceneprevious = this.sceneAllPiecesPot.scene.getObjectByName(this.previousItemID.toString());
          if (objectinSceneprevious) this.sceneAllPiecesPot.scene.remove(objectinSceneprevious);
        }

      }

       
      if(!objectinScene){//if the  item does not exist add it
        //add the itemID and number of step
        // console.log("please add to the log this piece");
        //initialise the log
        this.thispiecesAttempt = {} as PieceAttempt;
       this.logPieceNumber(this.itemID);
        

        if (this.item) {

          this.scenePiecePot.setupModelPiece(this.item,this.texture,this.itemID,this.pieceTransparency/10);
          this.sceneAllPiecesPot.setupPuzzlePieceClue(this.item,this.itemID,this.showPieceHelp);
          this.itemProcessingisDone = false;
          //load the previous itemID so we can unload it when there is a new one
          this.previousItemID = this.itemID;

          }
        
      } else{//if item already exist remove it

        this.scenePiecePot.scene.remove(objectinScene);
        let objectinScene1 = this.sceneAllPiecesPot.scene.getObjectByName(this.itemID.toString());
        if (objectinScene1) this.sceneAllPiecesPot.scene.remove(objectinScene1);
        // this.sceneFullPot.setupPiece(this.item,this.itemID);
        // this.doneItem.emit("this.itemID.toString()");
        this.returnItem.emit(this.itemID);
        this.itemProcessingisDone = true;
      }   

     

    }
  }
  //do if the piece matches when testing how close is the  position  to the camera
  pieceMatches(){
    //only do if ID is not null
      if (this.itemID.toString()!=""){
      //setup the piece in the scene
      // this.sceneFullPot.setupPiece(this.item, this.itemID.toString());
      //remove object from plane
      var objectinClueScene =  this.sceneAllPiecesPot.scene.getObjectByName(this.itemID.toString()) as THREE.Mesh;
      var objectinCameraScene = this.scenePiecePot.scene.getObjectByName(this.itemID.toString()) as THREE.Mesh;
      // console.log("objectinClueScene",objectinClueScene);
      // console.log("objectinCameraScene ",objectinCameraScene);
      if ((objectinClueScene)&&(objectinCameraScene)){
        //game AssetShowing
        // var gameAssetShowing = this.scenePiecePot.getMainPuzzleAsset();
        var material = objectinCameraScene.material as THREE.MeshStandardMaterial;
        material.opacity = 1;
        // var material = gameAssetShowing.material;
        objectinClueScene.material = material.clone();
        objectinClueScene.visible = true;
        // console.log("this is the object we need to change material: ",objectinClueScene);

        // let pa = {stepNum:1, puzzlePieceNumSelected : '133331'} as PieceAttempt;
        this.logPieceEndTime();
        this.firestore.collection(this.collection).doc(this.userID).collection(this.attemptcollection).doc(this.userID+'_'+this.thispiecesAttempt.stepNum).set(this.thispiecesAttempt)
        // .then(() => {
        //   console.log("Collection added to Firestore!");
        // })
        // .catch(function(error){
        //   console.log("Error adding document to Firestore: " + error);
        // });


        // var mesh = new THREE.Mesh();
        // this.sceneAllPiecesPot.scene.add();

        
        // const newmaterial = gameAssetShowing.material;

        // this.sceneAllPiecesPot.scene.getObjectByName(this.itemID.toString()).material.needsUpdate = true;
        // material.needsUpdate = true

        // objectinClueScene.
        
        // objectinClueScene!.visible =false;
        // objectinCameraScene!.
        
        // .material.transparent = true;
        // this.sceneAllPiecesPot.scene.add(objectinCameraScene!);

        // this.sceneClues.puzzleAsset.uniforms.diffuseColor.value =  new Color(0XFF0000);
        //remove from camera scene
        // let objectinScene1 = this.scenePiecePot.scene.getObjectByName(this.itemID.toString());
        // if (objectinClueScene) this.scenePiecePot.scene.remove(objectinCameraScene);

        if (objectinCameraScene) this.scenePiecePot.scene.remove(objectinCameraScene);
        //this.sceneClues.setupModelClue(this.item,this.itemID);
        // this.sceneAllPiecesPot.puzzleAsset.uniforms.alpha.value = 1.0;
        //emit this is done
        // console.log("ITEMID --- ",this.itemID);
        this.doneItem.emit(this.itemID);
        this.itemProcessingisDone = true;
        this.showPieceHelp = false;
      }
    }
  }
  // updateHelperPiece(updatePlanePieces : boolean, pieceTransparency : number){
  //   //if the  item does not exist add it
  //   if(this.itemID){
  //     var objectinScene = this.scenePiecePot.scene.getObjectByName(this.itemID.toString());

  //     if(!objectinScene){
  //       if (this.item) {
  //         //only update the piece on the  plane when the button is clicked  (on ngChanges)
  //         if (updatePlanePieces) this.scenePiecePot.setupModelPiece(this.item,this.itemID);
  //         //if (this.showPieceHelp) this.sceneClues.setupModelClue(this.item,this.itemID);
  //         this.itemProcessingisDone = false;
  //         this.pieceTransparency = pieceTransparency;
  //       }
  //     }
  //     //if item already exist remove it
  //     else{
  //       if (updatePlanePieces) this.scenePiecePot.scene.remove(objectinScene);
  //       let objectinScene1 = this.sceneClues.scene.getObjectByName(this.itemID.toString());
  //       if (objectinScene1) this.sceneClues.scene.remove(objectinScene1);
  //       // this.sceneFullPot.setupPiece(this.item,this.itemID);
  //       // this.doneItem.emit("this.itemID.toString()");
  //       this.returnItem.emit(this.itemID);
  //   }
  
  //   }
  // }
  //*********************************************************************************/
  //*********************************************************************************/
  //************************HANDLERS OF ANGULAR TOGGLES******************************/
  //*********************************************************************************/
  //*********************************************************************************/
  onInputChangePiece(event: MatSliderChange) {
    if (event.value) {
      //map the value to our range
      this.scenePiecePot.puzzleAsset.material.opacity = (event.value/10);
      this.pieceTransparency = event.value;
    }
  }
  // onInputChangeAllPieces(event: MatSliderChange) {
  //   if (event.value) {
  //     // console.log(this.sceneClues.clues); // prints values: 10, 20, 30, 40
  //     //map the value to our range
  //     for (var item of this.sceneClues.clues) {
  //       // console.log(item); // prints values: 10, 20, 30, 40
  //       item.uniforms.alpha.value = (event.value/10);
  //     }
  //   }
  // }
  // onInputChangePot(event: MatSliderChange) {
  //   if (event.value) 
  //     //map the value to our range
  //     this.sceneFullPot.puzzleAsset.uniforms.alpha.value =  (event.value*this.maxTransparency/10);

  // }
  // onInputChangeCore(event: MatSliderChange) {
  //   if (event.value) 
  //   //map the value to our range
  //   this.sceneClues.innerCore.uniforms.alpha.value =  (event.value*this.maxTransparency/10);

  // }
  
  // onToggleCore(event: MatSlideToggleChange){
  //   let objectInnerCore1 = this.sceneClues.scene.getObjectByName("innerCore1");
  //   let objectInnerCore2 = this.sceneClues.scene.getObjectByName("innerCore2");
  //   if (event.checked) {
  //     objectInnerCore1!.visible = true;
  //     objectInnerCore2!.visible = true;
  //   }else{
  //     objectInnerCore1!.visible = false;
  //     objectInnerCore2!.visible = false;
  //   }
  // }
  // onTogglePot(event: MatSlideToggleChange){
  //   let objectMainPuzzle = this.sceneFullPot.scene.getObjectByName("mainPuzzleCore");
  //   console.log(objectMainPuzzle);
  //   if (event.checked) {
  //     objectMainPuzzle!.visible = true;
  //   }else{
  //     objectMainPuzzle!.visible = false;
  //   }
  // }


  onTogglePieces(event: MatSlideToggleChange){
    this.usedCluesItem.emit(); // set clues
    //when someone enables the toggle
    if (event.checked){
      //first check if there is an itemID loaded
      if((this.itemID)&&(this.itemProcessingisDone==false)){
        // console.log("I am here and should be showing it",this.itemID);
        //check if the  object has been loaded
        var objectinScene = this.sceneAllPiecesPot.scene.getObjectByName(this.itemID.toString());
        //if it is not loaded, then do
        // console.log("objectins cene:  ",objectinScene);
        if (objectinScene) {
          objectinScene!.visible = true;
        } 
      }
      //let everyone else know is enabled
      this.showPieceHelp = true;
    }else{
      //when the toggle is  disabled
      //first check if there is an itemID loaded
      if((this.itemID)&&(this.itemProcessingisDone==false)){
        // console.log("I am false ",this.itemID);
        //check if the  object has been loaded
        var objectinScene = this.sceneAllPiecesPot.scene.getObjectByName(this.itemID.toString());
        // if it is, then remove it
        if (objectinScene) {
     
          objectinScene.visible = false;
         
        }

      }
      //let everyone else know this is disabled
      this.showPieceHelp = false;
    }
    this.logShowPieceHelp();
  
    // if(this.itemID){
    //   var objectinScene = this.scenePiecePot.scene.getObjectByName(this.itemID.toString());

    //     if (this.item) {
    //       this.sceneClues.setupModelClue(this.item,this.itemID);
    //       // this.sceneClues.puzzleAsset.uniforms.alpha.value = (this.pieceTransparency*this.maxTransparency/10);
    //     }
    //   }
    //   //if item already exist remove it
    //   else{
    //     let objectinScene1 = this.sceneClues.scene.getObjectByName(this.itemID.toString());
    //     if (objectinScene1) this.sceneClues.scene.remove(objectinScene1);
    //   }
    // }
  }
  // onToggleAllPieces(event: MatSlideToggleChange){
  //   //when someone enables the toggle
  //   if (event.checked){
      
  //     // console.log("disabeld");
  //     this.sceneClues.setupModelCores(this.numPieces,this.clues);
  //   }else{
  //     for (var item of this.sceneClues.clues) {
  //       // console.log(item); // prints values: 10, 20, 30, 40
  //       var name  = item.name
  //       var objectinScene = this.sceneClues.scene.getObjectByName(item.name.toString());
  //       if (objectinScene) {
  //         this.sceneClues.scene.remove(objectinScene);
  //       }
  //     }
  //   }
  // }
  //here I am going to get the camera distance
  setupMainModel() : Promise<Vector3> {

    return new Promise((resolve,reject) =>{
      this.sceneFullPot.setupModel(this.mainfile,"mainPuzzleCore").then( data => {
        var pos = new Vector3(this.sceneFullPot.camera.position.x,this.sceneFullPot.camera.position.y,this.sceneFullPot.camera.position.z);
        // console.log(pos);
        resolve(pos);
      
      });
    });
  }

  // @HostListener('window:beforeunload', ['$event'])
  // doSomething($event : any) {
  //   console.log("aaa");
  //     this.firestore.collection('users').doc('UserData').set(this.thisUser).then(
  //     (res) => {
  //       console.log(res);
  //     }
  //   ).catch((error)=>{
  //     console.log("There is an error");
  //   });
  //   // if(this.hasChanges) $event.returnValue='Your data will be lost!';
  // }

  // beforeunload(){
  //   console.log("on destroy");
  //   // this.usersCollection = firestore.collection('items').valueChanges();
  //   this.firestore.collection('users').doc('UserData').set(this.thisUser).then(
  //     (res) => {
  //       console.log(res);
  //     }
  //   ).catch((error)=>{
  //     console.log("There is an error");
  //   });


    
  // }
  logPieceNumber(id : string){
    this.thispiecesAttempt.puzzlePieceNumSelected = id;
    this.thispiecesAttempt.stepNum = this.step++;
    this.thispiecesAttempt.usedClues = this.showPieceHelp;
    this.start = Date.now();
    this.total_angle_azimuth = 0;
    this.total_angle_polar = 0;
    
    this.thispiecesAttempt.startTime = this.start.toString();
  }
  logPieceEndTime(){
    let endTime =  Date.now();
    this.thispiecesAttempt.endTime = endTime.toString();
    let cTime = endTime - this.start;
    this.thispiecesAttempt.completionTimePiece = cTime;
    this.thispiecesAttempt.succesfulAttempt = true;
    this.thispiecesAttempt.transparencyLevel = this.pieceTransparency;
    this.logAnglesCamera();
    
  }
  logShowPieceHelp(){
    this.thispiecesAttempt.usedClues = this.showPieceHelp;
  }
  logAnglesCamera(){
    this.thispiecesAttempt.rotationCameraAzimuth = this.total_angle_azimuth;
    this.thispiecesAttempt.rotationCameraPolar = this.total_angle_polar;
  }





}
