<!-- <mat-toolbar color="primary" class="titleToolbar">
	<span>Kundekari Technique: How To Make Woodwork Art</span>
</mat-toolbar>

<dialog id="myDialog"></dialog> -->

 	<div id="maincontainer">
		 <div id="areaLeft">
			<!-- <div id="counterText">
				<countup-timer [countUpTimerConfig]="testConfig" ></countup-timer>
			</div> -->
			<!-- <puzzle-core mainfile="/assets/puzzle1/puzzleall.glb" [item]="itemToAdd"  colorViewer="#4D5061"></puzzle-core>  -->
			<view-gl [mainfile]="fileallpuzzle" [filepath]="filepath" [clues]="clues"
			[numPieces]="files.length" [itemID]="itemIDToAdd" [item]="itemToAdd" [texture]="itemTexToAdd" [itemState]="itemState" 
			[userID]="id" 
				(doneItem)="onDone($event)" (returnItem)="onReturn($event)" (usedCluesItem)="onUsedClues()" colorViewer="#4D5061"></view-gl>

		</div> 
		<!-- <div id="areaCenter">

			<p id="titlePieces">
				Puzzle pieces
			</p>
			<div id="errorMessages"></div>

		<div class="example-container">
					<div class="example-box"  >

						<ul class="flex-container row">
							<li class="flex-item" *ngFor="let item of files">

								<button id={{item.num}} mat-raised-button (click)="myOnClick($event);"  
								[ngClass]="getCustomCss(item.num, item.disabled)" >
								
								</button>
							</li>

						</ul>

				</div>
			</div>

		</div> -->
	
	<router-outlet></router-outlet>
	</div>