// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//https://github.com/angular/angularfire/blob/master/docs/install-and-setup.md
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBp0IKaJsmtd7ts2AEg9o8_C96DFkjxdYo",
    authDomain: "timespuzzle-e3853.firebaseapp.com",
    projectId: "timespuzzle-e3853",
    storageBucket: "timespuzzle-e3853.appspot.com",
    messagingSenderId: "473550768671",
    appId: "1:473550768671:web:f9aba9fba63b826cea632a"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
