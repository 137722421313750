import * as THREE from 'three';
import { Scene,
    PerspectiveCamera,  Object3D, Vector3, ShaderMaterial, Vector, Color, Vector2 } from 'three';
    
import { GameAsset } from './game-asset';
import { SceneBasic } from './scene-basic';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';


//direct child of Scene All, it has a method to setup an invisible main model which is used to calculate the centre of the screen
// as well as a method to add one piece which will be viewed by a particular camera which looks straight at the piece
export class ScenePiece extends SceneBasic {

  
  constructor(width : number, height : number){
    super(width,height);

  }
  setupModelPiece(file : string, texture: string, name : string, opacity : number){
  
    //use the main puzzle asset object to load the piece
    this.puzzleAsset.loadFile(file)
    .then((result) =>{
      // console.log(result);
      //set the material
      const canvas1 = <HTMLCanvasElement>  document.querySelector('#c1');
      // var material;
      const textureDiff = new THREE.TextureLoader().load( 
        // resource URL
        texture,
        // onLoad callback
         ( texture ) => {

          texture.flipY = false;
          // in this example we create the material when the texture is loaded
          // this.puzzleAsset.material = new THREE.MeshStandardMaterial( {
          //   map: texture,
          //   transparent: true,
          //   opacity: 0.8,
          // } );
          //modify the material to get the texture and opacity to transparent
          this.puzzleAsset.material.map = texture;
          this.puzzleAsset.material.opacity = opacity;
          // this.puzzleAsset.material.premultipliedAlpha = true;
          // this.puzzleAsset.material.blending = THREE.CustomBlending;
          // this.puzzleAsset.material.blendSrc = THREE.DstAlphaFactor;
          // this.puzzleAsset.material.blendDst = THREE.OneMinusSrcAlphaFactor;
          // this.puzzleAsset.material.blendEquation = THREE.AddEquation;

    //   blendDst : THREE.OneMinusSrcAlphaFactor,
    //   blendEquation : THREE.AddEquation,
          // console.log("mat"+this.puzzleAsset.material);
         

        var meshPiece = new THREE.Mesh(result[0].geometry,  this.puzzleAsset.material);
        // var meshPiece = new THREE.Mesh(result[0].geometry,  this.puzzleAsset.material);
        //  console.log("mat added"+this.puzzleAsset.material);
        //set the name
        this.puzzleAsset.name = name;
        meshPiece.name = name;
        meshPiece.position.set(result[0].position.x, result[0].position.y, result[0].position.z);
        this.scene.add(meshPiece);
        
        //compute its bounding box
        const bbox = new THREE.Box3().setFromObject(meshPiece);
        let pieceCentre = bbox.getCenter(new Vector3());
    
        // controls.enableRotate = true;
        let targetVector = this.controls.target ;
        
        //get the vector which defines the camera position minus the target
        let v1 = new Vector3(this.camera.position.x-targetVector.x,
        this.camera.position.y-targetVector.y,
        this.camera.position.z-targetVector.z);
        //get the vector that defines a line passing between both centres
        let v2 = pieceCentre;

        //get the appropiate rotation for these both vectors
        var quaternion = new THREE.Quaternion(); // create one and reuse it
        quaternion.setFromUnitVectors( v1.normalize(), v2.normalize() );
        var matrix = new THREE.Matrix4(); // create one and reuse it
        matrix.makeRotationFromQuaternion( quaternion );
        this.camera.applyMatrix4( matrix );
        this.camera.lookAt(targetVector);
       
        })

    }

    ).catch(function(error){
      console.log("there was an error in the process");
    })
  }

}